/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 全局常量配置
 * @Date: 2022-04-02 14:38:12
 * @LastEditTime: 2022-04-09 16:50:19
 */

// 订单类型
export const ORDER_TYPE = {
  buy: {
    value: 1,
    label: '购买'
  },
  renew: {
    value: 2,
    label: '续费'
  },
  refund: {
    value: 3,
    label: '退款'
  },
  // recharge: {
  //   value: 4,
  //   label: '充值'
  // },
  compensate: {
    value: 5,
    label: '补偿'
  },
  // give: {
  //   value: 6,
  //   label: '赠送'
  // }
}


// 支付方式
export const PAY_TYPE = {
  ali: {
    value: 1,
    label: '支付宝'
  },
  weChat: {
    value: 2,
    label: '微信'
  },
  balance: {
    value: 4,
    label: '余额支付'
  },
  offLine: {
    value: 5,
    label: '线下支付'
  },
  ofLineAli: {
    value: 6,
    label: '线下支付宝'
  },
  offLineWeChat: {
    value: 7,
    label: '线下微信'
  },
  offLineCommon: {
    value: 8,
    label: '线下对公'
  }
}


// 支付方式
export const ORDER_STATUS = {
  payNo: {
    value: 1,
    label: '待支付'
  },
  weChat: {
    value: 2,
    label: '已支付'
  },
  balance: {
    value: 3,
    label: '已取消'
  },
  offLine: {
    value: 4,
    label: '退款中'
  },
  ofLineAli: {
    value: 5,
    label: '退款成功'
  },
  offLineWeChat: {
    value: 6,
    label: '退出失败'
  },
}