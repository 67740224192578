/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: 订单类型
 * @Date: 2022-04-02 14:56:57
 * @LastEditTime: 2022-04-02 15:06:48
 */



import { Select } from "antd";
import { ORDER_TYPE } from "src/config/constant"
export default function OrderType() {

  return (
    <React.Fragment>
      <Select.Option value={null}>全部</Select.Option>
      {
        Object.values(ORDER_TYPE)?.map((item, index) => {
          return (<Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>)
        })
      }
    </React.Fragment>
  )

}