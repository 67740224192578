/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 客户详情
 * @Date: 2022-03-21 15:37:26
 * @LastEditTime: 2022-04-09 16:46:31
 */

import "./index.less"
import { Layout, Form, Input, Select, Button, DatePicker, Table, Tabs, Row, Col, message } from "antd";
const { TabPane } = Tabs;
import { useNavigate, useLocation } from "react-router-dom"
import { useEffect } from "react"
import { StickyContainer, Sticky } from 'react-sticky';
import store from "src/store"
import moment from "moment"

import HistoryOrder from "views/orders/history"


// 枚举信息
import { _payTypeOption, _mealTypeOption, _mealStateOption, _ipTypeOption } from 'src/utils/enumerateData'
import { historyPackage, historyOrders, histortOrder, ipWhite } from "./table.config"
//         客户详情              二级客户套餐          客户白名单
import { queryCustomerDetail, queryCustomerMeal, queryWhiteList } from "model/customer"

import ico from "assets/images/tx.png"

export default function Detail() {
  const state = store.getState();
  let userInfo = (state && state.userInfo && typeof state.userInfo === 'string') ? JSON.parse(state.userInfo) : state.userInfo || null;
  // 用户详情信息
  let [customerData, setCustomerData] = React.useState({})
  let location = useLocation();
  // 路由携带参数
  const routeQuery = {
    brand: userInfo.brand,
    userId: location.state && location.state.userId && Number(location.state.userId)
  }
  // 表明当前所属tab
  const [tabsKey, setTabsKey] = React.useState(0);
  //历史套餐查询参数
  const [mealParams, setMealParams] = React.useState({
    sequenceList: null,   // 套餐ID列表
    name: "",  // 套餐名称
    mealType: null,  // 套餐类型
    key: "",   // 提取密钥
    proxyState: null,  // 套餐状态
    ipType: null,  // IP类型
    brand: null,
    times: null,  // 时间范围
    remainDays: null,  // 剩余天数
    payType: null,  // 付费类型
  })
  // 白名单查询参数   不要分页
  const [whiteListParams, setWhiteListParams] = React.useState({
    userId: location.state && location.state.userId && Number(location.state.userId),
    clientIP: null,  // IP
    addTimes: null,  // 添加时间的范围
    delTimes: null,  // 删除时间的范围
  })
  const [page, setPage] = React.useState({
    page: 1,
    size: 20
  })
  const [total, setTotal] = React.useState(0);
  // 表格数据
  const [tableData, setTableData] = React.useState([]);
  const [whiteTable, setWhiteTable] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [whiteLoading, setWhiteLoading] = React.useState(false);
  // 分页参数
  const pagination = {
    simple: false,
    total: total,
    showQuickJumper: true,
    current: page.page,
    pageSize: page.size,
    pageSizeOptions: ['10', '20', '30', '40', '50'],
    showSizeChanger: true,
    showTotal: (count = total) => {
      return '共' + count + '条数据'
    },
    onChange: (page, size) => {
      setPage({
        page, size
      })
    },
  }
  // 获取用户详情
  const getCustomerDetail = async () => {
    let res = await queryCustomerDetail(routeQuery)
    if (res && res.code === 200) {
      // 无法修改对象数据
      let data = Object.assign({}, customerData, { ...res.data })
      console.log(data,'data===');
      setCustomerData(data)
    }
  }
  //监听传参
  const updateParams = (val, field) => {
    // if (field != 'times' && field != 'addTimes' && field != 'delTimes') {
      if (Number(tabsKey) === 1) {
        if (field != 'times') {
          setMealParams({
            ...mealParams,
            [field]: val
          })
          return
        }
        //处理时间范围
        setMealParams({
          ...mealParams,
          startTime: val && val[0]._d,
          endTime: val && val[1]._d
        })

      }

      if (Number(tabsKey) === 2) {
        console.log('修改历史套餐查询数据')
      }

      if (Number(tabsKey) === 3) {
        // console.log('修改白名单查询数据')
        if (field !== 'addTimes' && field !== 'delTimes') {
          setWhiteListParams({
            ...whiteListParams,
            [field]: val
          })
          return
        }
        if (field == 'addTimes') {
          //处理时间范围
          setWhiteListParams({
            ...whiteListParams,
            addStartTime: val && val[0]._d,
            addEndTime: val && val[1]._d
          })
          return
        }
        if (field == 'delTimes') {
          //处理时间范围
          setWhiteListParams({
            ...whiteListParams,
            delStartTime: val && val[0]._d,
            delEndTime: val && val[1]._d
          })
          return
        }

      }

    // }



  }
  // 获取历史套餐
  const getHistoryMeal = async (val = null) => {
    setLoading(true)
    let tmp = val ?? mealParams;
    console.log(tmp,'tmp====');
    const query = {
      agentId: userInfo.agentId || null,  // 代理商ID
      userId: location.state && location.state.userId && Number(location.state.userId),  // 	用户ID
      brand: userInfo.brand || null,
      sequenceList: tmp.sequenceList ? [tmp.sequenceList] : [],   // 套餐ID列表
      name: tmp.name || null,
      mealType: tmp.mealType ? Number(tmp.mealType) : null,  // 套餐类型
      key: tmp.key || null,   // 提取密钥
      proxyState: tmp.proxyState ? Number(tmp.proxyState) : null,  // 套餐状态
      ipType: tmp.ipType ? Number(tmp.ipType) : null,  // IP类型
      createTimeStart: tmp.startTime ? Math.floor(((new Date(tmp.startTime)).getTime()) / 1000) : null,  // 购买开始时间
      createTimeEnd: tmp.endTime ? Math.floor(((new Date(tmp.endTime)).getTime()) / 1000) : null,  // 购买结束时间
      remainDays: tmp.remainDays ? Number(tmp.remainDays) : null,  // 剩余天数
      payType: tmp.payType ? Number(tmp.payType) : null,  // 付费类型
      ...page
    }
    let res = await queryCustomerMeal(query)
    if (res && res.code === 200) {
      setLoading(false)
      const data = res.data && res.data.list && [...res.data.list] || [];
      const total = res.data && res.data.total || 0;
      setTotal(total)
      setTableData(data)
    } else {
      setLoading(false)
      return message.error(res.message || '系统异常')
    }
  }
  // 获取白名单数据
  const getWhiteList = async (val = null) => {
    setWhiteLoading(true)
    let tmp = val ?? whiteListParams;
    console.log(tmp,'tmp===');
    const query = {
      userId: location.state && location.state.userId && Number(location.state.userId),  // 	用户ID
      clientIP: tmp.clientIP || null,
      startCreateTime: tmp.addStartTime ? Math.floor(((new Date(tmp.addStartTime)).getTime()) / 1000) : null,  // 添加开始时间
      endCreateTime: tmp.addEndTime ? Math.floor(((new Date(tmp.addEndTime)).getTime()) / 1000) : null,  // 添加结束时间
      startDeleteTime: tmp.delStartTime ? Math.floor(((new Date(tmp.delStartTime)).getTime()) / 1000) : null,  // 删除开始时间
      endDeleteTime: tmp.delEndTime ? Math.floor(((new Date(tmp.delEndTime)).getTime()) / 1000) : null,  // 删除结束时间
      page: tmp.page || 1,
      size: tmp.size || 20,
    }
    let res = await queryWhiteList(query);
    if (res && res.code === 200) {
      setWhiteLoading(false)
      const data = res.data && res.data.list && [...res.data.list] || [];
      const total = res.data && res.data.total || 0;
      setTotal(total)
      setWhiteTable(data)
    } else {
      setWhiteLoading(false)
      return message.error(res.message || '系统异常')
    }
  }
  //重置
  const formRefHistory = React.useRef(null);  // 历史套餐
  const formRefIpWhite = React.useRef(null);  // IP白名单
  const reset = async (tab) => {
    if(tabsKey === '1'){
      await formRefHistory.current.resetFields()
      const query = {
        sequenceList: null,   // 套餐ID列表
        name: "",  // 套餐名称
        mealType: null,  // 套餐类型
        key: "",   // 提取密钥
        proxyState: null,  // 套餐状态
        ipType: null,  // IP类型
        brand: null,
        times: null,  // 时间范围
        remainDays: null,  // 剩余天数
        payType: null,  // 付费类型
      }
      setMealParams({
        ...query
      })
      setPage({
        page: 1,
        size: 20
      })
    }

    if(tabsKey === '3'){
      await formRefIpWhite.current.resetFields()
      const query = {
        userId: location.state && location.state.userId && Number(location.state.userId),
        clientIP: null,  // IP
        addTimes: null,  // 添加时间的范围
        delTimes: null,  // 删除时间的范围
      }
      setWhiteListParams({
        ...query
      })
      setWhiteListParams(async (pre)=>{
        console.log(pre,'pre=====');
        await getWhiteList(pre)
        return pre
      })
    }

  }

  //查询
  const query = async (tab = 1) => {
    await tabsChange(tabsKey || '1')
  }

  const tabsChange = async (key) => {
    // 切换tab时将表格、数据总条数重置
    const data = [];
    const total = 0;
    setTotal(total)
    setTableData(data)
    setWhiteTable(data)
    setTabsKey(key)
    // 根据不同的key调取接口
    if (key === '1') {
      await getHistoryMeal()
    } else if (key === '2') {
      console.log('获取历史订单')
    } else {
      console.log('获取IP白名单')
      await getWhiteList()
    }
  }

  // 初始化进入界面调用接口
  useEffect(async () => {
    await getCustomerDetail()
    // console.log(customerData, '用户详情信息')
    // 根据tab停留页面调取接口
    await tabsChange(tabsKey || '1')
  }, [page]);



  const monthFormat = 'YYYY/MM/DD HH:mm:ss';

  function onChange(pagination, filters, sorter, extra) {
    // console.log('params', pagination, filters, sorter, extra);
  }

  const navigate = useNavigate()

  const goDetails = (row) => {
    console.log("点击不进入详情页", row)
  }

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
      )}
    </Sticky>
  );

  return (
    <>
      {/* 选项 */}
      <Layout.Content
        className="layout-content-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >

        <div className="user-detail flex">
          <div className="user-image">
            <img src={ico} />
          </div>
          <div className="user-info ml-40">
            <div className="user-name">
              <span>{customerData.username}</span>
              <span className="ml-20 font-main">{customerData.isIdentityAuth ? '已认证' : '未认证'}</span>
            </div>
            <div className="user-list mt-20">
              <ul className="flex">
                <li>
                  <label htmlFor="status" id="status">账号状态</label>
                  <span className="ml-10 font-main">
                    <span style={{color: customerData.state === 2 ? '#f10215' : '#4E9AFB'}}>
                      {customerData.state === 1 ? '启用' : '禁用'}
                    </span>
                  </span>
                </li>
                <li className="ml-20">
                  <label htmlFor="status" id="status">创建时间</label>
                  <span className="ml-10 font-main">{customerData.createTime && moment(customerData.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                </li>
                <li className="ml-20">
                  <label htmlFor="status" id="status">最近登录时间</label>
                  <span className="ml-10 font-main">{customerData.loginTime && moment(customerData.loginTime * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                </li>
                <li className="ml-20">
                  <label htmlFor="status" id="status">最近登录地点</label>
                  <span className="ml-10 font-main">{customerData.lastLoginRegion || '--'}</span>
                  <span className="ml-10 font-main">{customerData.lastIP || '--'}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout.Content>


      {/* table*/}
      <Layout.Content
        className="layout-content-background customer-detail"
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
        }}
      >
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={tabsChange}>
            <TabPane tab="历史套餐" key="1">

              <Form name="horizontal_login" layout="inline" ref={formRefHistory}>
                {/* 套餐id */}
                <Form.Item name="pid" label="套餐ID" className="mt-10 mb-10" colon={false}>
                  <Input style={{ width: 150 }} value={mealParams.sequenceList} placeholder="输入套餐ID" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'sequenceList')} />
                </Form.Item>

                {/* 套餐名称 */}
                <Form.Item name="name" label="套餐名称" className="mt-10 mb-10" colon={false}>
                  <Input style={{ width: 150 }} value={mealParams.name} placeholder="输入套餐名称" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'name')} />
                </Form.Item>

                {/* 套餐类型 */}
                <Form.Item name="mealType" label="套餐类型" className="mt-10 mb-10" colon={false}>
                  <Select defaultValue="全部" style={{ width: 120 }} value={mealParams.mealType} onChange={(e) => updateParams(e, 'mealType')}>
                    <Select.Option value={null}>全部</Select.Option>
                    {
                      Object.keys(_mealTypeOption).map(key => (
                        <Select.Option value={key} key={key}>{_mealTypeOption[key]}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>

                {/* 提取密钥 */}
                <Form.Item name="key" label="提取密钥" className="mt-10 mb-10" colon={false}>
                  <Input value={mealParams.key} style={{ width: 150 }} placeholder="输入提取密钥" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'key')} />
                </Form.Item>


                {/* 套餐状态 */}
                <Form.Item name="pstatus" label="套餐状态" className="mt-10 mb-10" colon={false}>
                  <Select defaultValue="全部" value={mealParams.proxyState} style={{ width: 120 }} onChange={(e) => updateParams(e, 'proxyState')}>
                    <Select.Option value={null}>全部</Select.Option>
                    {
                      Object.keys(_mealStateOption).map(key => (
                        <Select.Option value={key} key={key}>{_mealStateOption[key]}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>

                {/* ip类型 */}
                <Form.Item name="pip" label="IP类型" className="mt-10 mb-10" colon={false}>
                  <Select defaultValue="全部" value={mealParams.ipType} style={{ width: 120 }} onChange={(e) => updateParams(e, 'ipType')}>
                    <Select.Option value={null}>全部</Select.Option>
                    {
                      Object.keys(_ipTypeOption).map(key => (
                        <Select.Option value={key} key={key}>{_ipTypeOption[key]}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>

                {/* 购买时间 */}
                <Form.Item name="times" label="购买时间" className="mt-10 mb-10" colon={false}>
                  {/*defaultValue={[moment('2015/01/01', monthFormat), moment('2015/01/01', monthFormat)]}*/}
                  <DatePicker.RangePicker format={monthFormat} showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                  }} onChange={(e) => updateParams(e, 'times')} />
                </Form.Item>


                {/* 剩余天数 */}
                <Form.Item name="remainDays" label="剩余天数" className="mt-10 mb-10" colon={false}>
                  <Input style={{ width: 150 }} value={mealParams.remainDays} placeholder="输入天数" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'remainDays')} />
                </Form.Item>

                {/* 付费类型 */}
                <Form.Item name="cost" label="付费类型" className="mt-10 mb-10" colon={false}>
                  <Select defaultValue="全部" style={{ width: 120 }} value={mealParams.payType} onChange={(e) => updateParams(e, 'payType')}>
                    <Select.Option value={null}>全部</Select.Option>
                    {
                      Object.keys(_payTypeOption).map(key => (
                        <Select.Option value={key} key={key}>{_payTypeOption[key]}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>

                {/* 查询/重置 */}
                <Form.Item className="action-obn mt-10 mb-10">
                  <Button type="primary" onClick={reset}>
                    重置
                  </Button>
                  <Button className="ml-10" style={{ backgroundColor: '#FD9924', borderBlockColor: '#FD9924', color: 'white' }} onClick={query}>
                    查询
                  </Button>
                </Form.Item>
              </Form>

              {/* 详情列表 */}
              <Table className="mt-40" 
                columns={historyPackage} 
                dataSource={tableData} 
                loading={loading}
                onChange={onChange} 
                pagination={pagination}
                scroll={{ x: 'calc(700px + 50%)' }} 
              />
            </TabPane>
            <TabPane tab="历史订单" key="2">
               <div className="history-box">{HistoryOrder(routeQuery.userId)}</div>
            </TabPane>
            <TabPane tab="IP白名单" key="3">
              <Form name="horizontal_login" layout="inline" ref={formRefIpWhite}>
                {/* IP地址 */}
                <Form.Item name="clientIP" label="IP地址" className="mt-10 mb-10" colon={false}>
                  <Input style={{ width: 150 }} value={mealParams.clientIP} placeholder="输入IP地址" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'clientIP')} />
                </Form.Item>

                {/* 添加时间 */}
                <Form.Item name="addTimes" label="添加时间" className="mt-10 mb-10" colon={false}>
                  <DatePicker.RangePicker showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                  }} format={monthFormat} onChange={(e) => updateParams(e, 'addTimes')} />
                </Form.Item>

                {/* 删除时间 */}
                <Form.Item name="delTimes" label="删除时间" className="mt-10 mb-10" colon={false}>
                  <DatePicker.RangePicker showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                  }} format={monthFormat} onChange={(e) => updateParams(e, 'delTimes')} />
                </Form.Item>

                {/* 查询/重置 */}
                <Form.Item className="action-obn mt-10 mb-10">
                  <Button type="primary" onClick={reset}>
                    重置
                  </Button>
                  <Button className="ml-10" style={{ backgroundColor: '#FD9924', borderBlockColor: '#FD9924', color: 'white' }} onClick={query}>
                    查询
                  </Button>
                </Form.Item>
              </Form>

              {/* 详情列表 */}
              <Table className="mt-40"
                scroll={{ x: 'calc(700px + 50%)' }}
                loading={whiteLoading}
                columns={ipWhite}
                dataSource={whiteTable}
                pagination={false}
                rowKey={record => record.id}
                onChange={onChange}
              />
            </TabPane>
          </Tabs>
        </StickyContainer>


      </Layout.Content>
    </>
  )
}
