/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description:  认证页
 * @Date: 2022-03-24 10:37:59
 * @LastEditTime: 2022-04-11 15:00:35
 */

import "./index.less"
import { Steps, Button } from 'antd';
const { Step } = Steps;

import AuthPhone from "./auth_phone"
import AuthRealName from "./auth_realName"
import AuthRes from "./auth_res"

export default function Auth() {

  //当前校验阶段
  const [current, setCurrent] = React.useState(0);


  const next = () => {
    console.log("点击下一步")
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  //校验的各个步骤配置
  const steps = [
    {
      title: '手机号认证',
      content: <AuthPhone next={next} />,
    },
    {
      title: '实名身份认证',
      content: <AuthRealName  next={next}/>,
    },
    {
      title: '认证成功',
      content:<AuthRes/>,
    },
  ];

  return (
    <div className="auth">
      <div className="step">
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>

      {current != null && <div className="step-content">{steps[current].content}</div>}
    </div>
  )
}