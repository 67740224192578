/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 客户套餐
 * @Date: 2022-03-21 15:37:26
 * @LastEditTime: 2022-04-09 16:35:14
 */

import "./index.less"
import { Layout, Form, Input, Select, Button, DatePicker, Table } from "antd";
import moment from "moment"
import { useEffect } from "react"
import store from "src/store"
import { useNavigate } from "react-router-dom"
import { tableConfig, tableData } from "./table.config"
import {_ipTypeOption, _mealStateOption, _mealTypeOption, _payTypeOption} from "../../../utils/enumerateData";
import { queryCustomerMeal } from "model/customer"

export default function CustomerList() {
  const state = store.getState();
  let userInfo = (state && state.userInfo && typeof state.userInfo === 'string') ? JSON.parse(state.userInfo) : state.userInfo || null;

  // 查询参数
  const [params, setParams] = React.useState({
    sequenceList: null,   // 套餐ID列表
    name: "",  // 套餐名称
    mealType: null,  // 套餐类型
    key: "",   // 提取密钥
    proxyState: null,  // 套餐状态
    ipType: null,  // IP类型
    brand: null,
    times: null,  // 时间范围
    remainDays: null,  // 剩余天数
    payType: null,  // 付费类型
  })
  // 数据总数
  const [total, setTotal] = React.useState(0);
  // 分页
  const [page, setPage] = React.useState({
    page: 1,
    size: 20
  })
  
  // 表格数据
  const [tableData, setTableData] = React.useState([]);
  // const [pagination,]
  // 分页参数
  const pagination={
    simple:false,
    total: total,
    showQuickJumper: true,
    current: page.page,
    pageSize: page.size,
    pageSizeOptions: ['10','20','30','40','50'],
    showSizeChanger: true,
    showTotal: (count=total)=>{
      return '共'+count+'条数据'
    },
    onChange: (page, size) => {
      setPage({
        page, size
      })
    },
  }
  //监听传参
  const updateParams = (val, field) => {
    if (field != 'times') {
      setParams({
        ...params,
        [field]: val
      })
      return
    }

    //处理时间范围
    setParams({
      ...params,
      startTime: val && val[0] && val[0]._d,
      endTime: val && val[1] && val[1]._d
    })
  }
  const getData = async (val = null) => {
    let tem = val ?? params;
    const query = {
      agentId: userInfo.id || null,  // 代理商ID
      userId: null,  // 	用户ID
      brand: userInfo.brand || null,
      sequenceList: tem.sequenceList ? [tem.sequenceList] : [],   // 套餐ID列表
      name: tem.name || null,
      mealType: tem.mealType ? Number(tem.mealType) : null,  // 套餐类型
      key: tem.key || null,   // 提取密钥
      proxyState: tem.proxyState ? Number(tem.proxyState) : null,  // 套餐状态
      ipType: tem.ipType ? Number(tem.ipType) : null,  // IP类型
      createTimeStart: tem.startTime ? Math.floor(((new Date(tem.startTime)).getTime()) / 1000) : null,  // 购买开始时间
      createTimeEnd: tem.endTime ? Math.floor(((new Date(tem.endTime)).getTime()) / 1000) : null,  // 购买结束时间
      remainDays: tem.remainDays ? Number(tem.remainDays) : null,  // 剩余天数
      payType: tem.payType ? Number(tem.payType) : null,  // 付费类型
      ...page
    }

    let res = await queryCustomerMeal(query)
    if(res && res.code === 200){
      const data = res.data && res.data.list && [...res.data.list] || [];
      const total = res.data && res.data.total || 0;
      setTotal(total)
      setTableData(data)
    } else {
      return message.error(res && res.message || '系统异常')
    }
  }
  useEffect(async () => {
    console.log('重置江铜');
    await getData()
  }, [page]);
  //查询
  const query = async () => {
    await getData()
  }


  //重置
  const formRef = React.useRef(null)
  const reset =async () => {
    await formRef.current.resetFields()
    setParams({
      sequenceList: null,   // 套餐ID列表
      name: "",  // 套餐名称
      mealType: null,  // 套餐类型
      key: "",   // 提取密钥
      proxyState: null,  // 套餐状态
      ipType: null,  // IP类型
      brand: null,
      times: null,  // 时间范围
      remainDays: null,  // 剩余天数
      payType: null,  // 付费类型
    })
    setPage({
      page: 1,
      size: 20
    })
  }
  const monthFormat = 'YYYY/MM/DD HH:mm:ss';
  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <>
      {/* 选项 */}
      <Layout.Content
        className="layout-content-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >


        <Form name="horizontal_login" layout="inline" ref={formRef}>
          {/* 套餐id */}
          <Form.Item name="pid" label="套餐ID" className="mt-10 mb-10" colon={false}>
            <Input style={{ width: 150 }} value={params.sequenceList} placeholder="输入套餐ID" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'sequenceList')}/>
          </Form.Item>

          {/* 套餐名称 */}
          <Form.Item name="name" label="套餐名称" className="mt-10 mb-10" colon={false}>
            <Input style={{ width: 150 }} value={params.name} placeholder="输入套餐名称" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'name')}/>
          </Form.Item>

          {/* 套餐类型 */}
          <Form.Item name="mealType" label="套餐类型" className="mt-10 mb-10" colon={false}>
            <Select defaultValue="全部" style={{ width: 120 }} value={params.mealType} onChange={(e) => updateParams(e, 'mealType')}>
              <Select.Option value={null}>全部</Select.Option>
              {
                Object.keys(_mealTypeOption).map(key => (
                    <Select.Option value={key} key={key}>{_mealTypeOption[key]}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          {/* 提取密钥 */}
          <Form.Item name="key" label="提取密钥" className="mt-10 mb-10" colon={false}>
            <Input value={params.key} style={{ width: 150 }} placeholder="输入提取密钥" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'key')}/>
          </Form.Item>


          {/* 套餐状态 */}
          <Form.Item name="pstatus" label="套餐状态" className="mt-10 mb-10" colon={false}>
            <Select defaultValue="全部" value={params.proxyState} style={{ width: 120 }} onChange={(e) => updateParams(e, 'proxyState')}>
              <Select.Option value={null}>全部</Select.Option>
              {
                Object.keys(_mealStateOption).map(key => (
                    <Select.Option value={key} key={key}>{_mealStateOption[key]}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          {/* ip类型 */}
          <Form.Item name="pip" label="IP类型" className="mt-10 mb-10" colon={false}>
            <Select defaultValue="全部" value={params.ipType} style={{ width: 120 }} onChange={(e) => updateParams(e, 'ipType')}>
              <Select.Option value={null}>全部</Select.Option>
              {
                Object.keys(_ipTypeOption).map(key => (
                    <Select.Option value={key} key={key}>{_ipTypeOption[key]}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          {/* 购买时间 */}
          <Form.Item name="times" label="购买时间" className="mt-10 mb-10" colon={false}>
            {/*defaultValue={[moment('2015/01/01', monthFormat), moment('2015/01/01', monthFormat)]}*/}
            <DatePicker.RangePicker  format={monthFormat} showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
            }} onChange={(e) => updateParams(e, 'times')}/>
          </Form.Item>


          {/* 剩余天数 */}
          <Form.Item name="remainDays" label="剩余天数" className="mt-10 mb-10" colon={false}>
            <Input style={{ width: 150 }} value={params.remainDays} placeholder="输入天数" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'remainDays')}/>
          </Form.Item>

          {/* 付费类型 */}
          <Form.Item name="cost" label="付费类型" className="mt-10 mb-10" colon={false}>
            <Select defaultValue="全部" style={{ width: 120 }} value={params.payType} onChange={(e) => updateParams(e, 'payType')}>
              <Select.Option value={null}>全部</Select.Option>
              {
                Object.keys(_payTypeOption).map(key => (
                    <Select.Option value={key} key={key}>{_payTypeOption[key]}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          {/* 查询/重置 */}
          <Form.Item className="action-obn mt-10 mb-10">
            <Button type="primary" onClick={reset}>
              重置
            </Button>
            <Button className="ml-10" style={{ backgroundColor: '#FD9924', borderBlockColor: '#FD9924', color: 'white' }} onClick={query}>
              查询
            </Button>
          </Form.Item>
        </Form>


      </Layout.Content>


      {/* table*/}
      <Layout.Content
        className="layout-content-background"
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
        }}
      >

        {/* 详情列表 */}
        <Table columns={tableConfig} dataSource={tableData} onChange={onChange} pagination={pagination} scroll={{ x: 'calc(700px + 50%)' }} />
      </Layout.Content>
    </>
  )
}
