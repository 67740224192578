/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 手机号认证
 * @Date: 2022-03-24 11:24:07
 * @LastEditTime: 2022-04-11 17:08:43
 */

import { connect } from 'react-redux'
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link } from "react-router-dom"
import { minPHONE, CODE } from "src/config/regexp.config"
import { getAuthCode, checkCode } from "model/user"
import { useEffect, useReducer } from 'react'
import {useNavigate} from "react-router-dom"
import store from "src/store"
import {setLogin} from "../../store/action/user";
//已校验展示
const confirmRes = (phone) => {
  return (
    <div className="auth-phone-res">
      <span>已认证手机码</span>
      <span className='text-l ml-20 font-main font-weight'>{phone || 15850514497}</span>
    </div>
  )
}


 function AuthPhone(props) {
  //  const { userInfo } = props
     let userInfo = (props.userInfo && typeof props.userInfo === 'string') ? JSON.parse(props.userInfo) : props.userInfo || null;
     const setLoginAction = props.setLoginAction;
     const navigate = useNavigate()
     // console.log(navigate,'navigate')

  //校验数据
  const [phone, setPhone] = React.useState({
    code: userInfo && userInfo.phone || null,  //手机号
    checkCode: '',//验证码
    remember: false  // 默认不勾选
  })

  // 倒计时
  let [countDown, setCountDown] = React.useState(0);
  // 定时器
  let [timer, setTimer] = React.useState(0);
  // 判断是否存取缓存时间
  // const cacheTime = sessionStorage.getItem('_timer') || false;
  // const seconds =  Math.floor((new Date()).getTime() / 1000) - Math.floor(Number(cacheTime) / 1000);

  //是否已校验
  const [hasChecked, setHaschecked] = React.useState(userInfo && userInfo.phoneNumAuth || false)

  // 点击获取验证码按钮
  const getPhoneCode = async () => {
      const obj = Object.assign(phone)
      setPhone(obj)
      // 校验手机号
      const checkRes = checkParams(phone, false)
      if (!checkRes.isPass) {
        return message.error(checkRes.msg)
      }

      // 获取验证码
      setCountDown(60)
      // sessionStorage.setItem('_timer', (new Date()).getTime())

      let res = await getAuthCode();
      if(res && res.code === 200){
          // return message.success(res && res.message || '验证码发送成功')
          return message.success('验证码发送成功')
      } else {
        sessionStorage.removeItem('_timer')
        setCountDown(0)
        setTimer(0);
        return message.error(res && res.message || '发送失败')
      }
  }

  // 使用 useEffect 监听 countDown 变化
  useEffect(() => {
    // if(cacheTime && seconds < 60){
    //   setCountDown(60 - seconds)
    // }

    if (countDown > 0) {
      const newTimer = setInterval(() => {
        if (countDown < 1) {
          // sessionStorage.removeItem('_timer')
          clearInterval(newTimer);
        } else {
          setCountDown((countDown -= 1));
        }
      }, 1000);
      setTimer(newTimer);
    }

  }, [countDown]);

  // 组件销毁清除倒计时
  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  //修改已有的值
  const updatePhone = (val, type = 'code') => {
    const obj = Object.assign(phone, {
      [type]: val
    })
    setPhone(obj)
  }

  //校验传参  allCheck为false仅校验手机号
  const checkParams = (obj, allCheck = true) => {
    // console.log("校验提交数据", obj)
    const res = {
      isPass: true,
      msg: ''
    }
    if (!obj.code) {
      res.isPass = false
      res.msg = '请输入手机号码'
      return res
    }

    if (!minPHONE.test(obj.code)) {
      res.isPass = false
      res.msg = '请输入正确格式的手机号码'
      return res
    }
    if (!obj.remember) {
      res.isPass = false
      res.msg = '请先阅读并确认用户协议'
      return res
    }
    // 是否都校验
    if(allCheck){
      if (!obj.checkCode) {
        res.isPass = false
        res.msg = '请输入验证码'
        return res
      }

      if (!CODE.test(obj.checkCode)) {
        res.isPass = false
        res.msg = '请输入正确格式的验证码'
        return res
      }

      
    }

    return res
  }


  //点击下一步
  const onFinish = async () => {
    //如果已校验 直接下一步
    if (hasChecked) {
      return props.next()
    }

    //校验数据合法性
    const checkRes = await checkParams(phone)
    if (!checkRes.isPass) {
      return message.error(checkRes.msg)
    }

    let params = {
        num: phone.checkCode
    }
    let res = await checkCode(params);
    if(res && res.code === 200){
        // 验证码校验成功修改redux缓存
        userInfo.phoneNumAuth = true;
        localStorage.setItem('_agentInfo', JSON.stringify(userInfo))
        setLoginAction && setLoginAction(userInfo)
        //校验成功
        setHaschecked(true)
        props.next()
    } else {
      message.error(res && res.message || '登录失败')
    }

  };


  return (
    <div className='auth-phone pb-40'>
      <p>为保障您的账户安全，请进行信息认证</p>
      <p>平台授权支付宝安全认证，平台不会泄露您的认证信息</p>
      {
        !hasChecked && <Form
          name="checkPhone"
          initialValues={{ remember: phone.remember }}
          autoComplete="off"
        >
          <Input placeholder='输入手机号' value={phone.code} disabled maxLength="11" type="number" className='mt-20' onBlur={(ev) => updatePhone(ev.target.value)} />

          <div className='flex mt-20'>
            <Input placeholder='输入验证码' type="number" maxLength="6" onBlur={(ev) => updatePhone(ev.target.value, 'checkCode')} />
            <Button type="primary" onClick={getPhoneCode} disabled={countDown}>
              {countDown ? countDown : '获取验证码'}
            </Button>
          </div>

          <Form.Item name="remember" valuePropName="checked" className='mt-20'>
            <Checkbox onChange={(ev) => updatePhone(!phone.remember, 'remember')}>
              我已阅读并同意 <Link to="/agreement">《HTTP 用户协议》</Link>
            </Checkbox>
          </Form.Item> 
        </Form> || confirmRes(phone.code)
      }

      <div >
        <Button type="primary" onClick={onFinish}>
          下一步
        </Button>
      </div>
    </div>
  )

}


const mapState = (state) => ({
  userInfo: state.userInfo,
  isAuthenticated: state.isAuthenticated
})
const mapDispatch = (dispatch) => {
  return {
    setLoginAction: (val) => {
      dispatch(setLogin(val))
    }
  }
}
export default connect(
  mapState,
  mapDispatch
)(AuthPhone)
