/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: page description
 * @Date: 2022-03-22 16:57:49
 * @LastEditTime: 2022-04-11 14:47:44
 */



import moment from "moment";
import {_payTypeOption, _mealTypeOption, _mealStateOption, _ipTypeOption} from 'src/utils/enumerateData';

export const tableConfig = [
    {
        title: '套餐ID',
        align: 'center',
        dataIndex: 'sequence',
        onCell: () => {
            return {
                style: {
                    'minWidth': '120px',
                },
            };
        },
    },
    {
        title: '所属客户',
        align: 'center',
        dataIndex: 'username',
        onCell: () => {
            return {
                style: {
                    'minWidth': '120px',
                },
            };
        },
    },
    {
        title: '套餐名称',
        align: 'center',
        dataIndex: 'name',
        onCell: () => {
            return {
                style: {
                    'minWidth': '160px',
                },
            };
        },
    },
    {
        title: '套餐类型',
        align: 'center',
        dataIndex: 'proxyType',
        onCell: () => {
            return {
                style: {
                    'minWidth': '100px',
                },
            };
        },
        render: function (el, context) {
            return `${(context.proxyType || context.proxyType === 0) && _mealTypeOption[context.proxyType] || '--'}`
        }
    },
    {
        title: '付费类型',
        align: 'center',
        dataIndex: 'mealPayType',
        onCell: () => {
            return {
                style: {
                    'minWidth': '100px',
                },
            };
        },
        render: function (el, context) {
            return `${context.mealPayType && _payTypeOption[context.mealPayType] || '--'}`
        }
    },
    {
        title: '提取秘钥',
        align: 'center',
        dataIndex: 'key',
        onCell: () => {
            return {
                style: {
                    'minWidth': '150px',
                },
            };
        },
    },
    {
        title: '账号|密码',
        align: 'center',
        dataIndex: 'authKey',
        onCell: () => {
            return {
                style: {
                    'minWidth': '170px',
                },
            };
        },
        render: function (el, context) {
            return `${context.authKey} | ${context.authSecret}`
        }
    },
    {
        title: '提取上限',
        align: 'center',
        dataIndex: 'total',
        onCell: () => {
            return {
                style: {
                    'minWidth': '120px',
                },
            };
        },
    },
    {
        title: '已提取',
        align: 'center',
        dataIndex: 'used',
        onCell: () => {
            return {
                style: {
                    'minWidth': '120px',
                },
            };
        },
    },
    {
        title: '起止时间',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    'minWidth': '180px',
                },
            };
        },
        dataIndex: 'createTime',
        sorter: {
            compare: (a, b) => a.createTime - b.createTime,
            multiple: 1,
        },
        //只有包时套餐 按照此规则 3天<到期时间<30天，绿色；到期时间<3天，红色；到期时间>30天，不显示到期时间
        render: function (el, context) {
            const start = context.createTime && context.createTime || null;
            const end = context.endTime && context.endTime || null;
            const now = new Date().getTime()/1000;
            // 包时
            if(context.proxyType === 1){
                // 0~1小时
                if(((end - now) / 60 / 60) < 1 && ((end - now) / 60 / 60) > 0){
                    return <div className='wrap'>
                        <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='limit' style={{color: '#E6A23C'}}>即将到期</span>
                    </div>
                } else if((end - now) / 60 / 60 < 24 && (end - now) / 60 / 60 >=1){  // 1~24小时
                    return <div className='wrap'>
                        <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='limit' style={{color: '#E6A23C'}}>剩余{Math.floor((end - now) / 60 /60)}小时</span>
                    </div>
                } else if(((end - now) / 60 / 60 / 24) < 4 && ((end - now) / 60 / 60 / 24) > 1){  // 1~3天
                    return <div className='wrap'>
                        <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='limit' style={{color: '#E6A23C'}}>剩余{Math.floor((end - now) / 60 /60 / 24)}天</span>
                    </div>
                } else if(((end - now) / 60 / 60 / 24) <= 30 && ((end - now) / 60 / 60 / 24) >= 4){  // 4~30天
                    return <div className='wrap'>
                        <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='limit' style={{color: '#E6A23C'}}>剩余{Math.floor((end - now) / 60 /60 / 24)}天</span>
                    </div>
                } else {
                    return <div className='wrap'>
                        <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                        <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                    </div>
                }
            } else {
                return <div className='wrap'>
                    <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                    <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
                </div>
            }
        }
    },

    {
        title: '状态',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    'minWidth': '120px',
                },
            };
        },
        dataIndex: 'state',
        render: function (el, context) {
            if(el){
                if(el === 2 || el === 3 || el === 4){
                    return <span style={{color: '#f10215'}}>{context.state && _mealStateOption[context.state] || '--'}</span>
                } else {
                    return <span style={{color: '#4E9AFB'}}>{context.state && _mealStateOption[context.state] || '--'}</span>
                }
            } else {
                return '--'
            }

        }
    },
    {
        title: '关联订单',
        align: 'center',
        width: 160,
        dataIndex: 'orderNo'
    },
];


export const tableData = [
    {
        key: '1',
        pid: 'vvvvvvvvvvvvv',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '2',
        pid: '2222222222222222',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '3',
        pid: 'zzzzzzz',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '4',
        pid: 'bbbbbbbbbbbbbbbbbb',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '5',
        pid: 'cccccccccccccccccc',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '6',
        pid: 'zzzzzzzzzzzzzzzzz',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '7',
        pid: 'cccccccccccccccc',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '8',
        pid: 'aaaaaaaaaaaaaaaaa',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '9',
        pid: 'vvvvvvvvvvvvvvvvvv',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '10',
        pid: 'ssssssssssss',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '11',
        pid: '3333333333333333',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '12',
        pid: '99999999999999999',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '13',
        pid: '111111111111111111',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '14',
        pid: '3333333333333333',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '15',
        pid: 'ssssssssssssssssss',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5sssr4344',
        status: 1
    },
    {
        key: '16',
        pid: 'fsfsefwesresrds',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '17',
        pid: '42342423432432',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '18',
        pid: '3423423423424',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '19',
        pid: '999999999',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '20',
        pid: '11111666666',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '21',
        pid: '33333344444',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '22',
        pid: '3333322222',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '23',
        pid: '999988888',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },
    {
        key: '24',
        pid: '6666677777',
        pname: 'jon tom',
        ptype: 1,
        ctype: 2,
        ptoken: '22324dfs',
        name: 'gggddd',
        pwd: 322423423,
        timeActive: 30,
        limit: 300,
        has: 100,
        createTime: '2020/02/20',
        orderId: 'err4weerre5r4344',
        status: 1
    },

];
