/*
 * @Author: 秦琛
 * @LastEditors: 秦琛
 * @description: 登录相关接口
 * @Date: 2022-03-30 11:06:06
 * @LastEditTime: 2022-03-31 15:31:52
 */
import {$API, $post, $get} from "utils/ajax"

export const agentLogin = async(params = null) => {
    try {
      const res = await $post($API.AUTHLOGIN, params);
      return res;
    } catch (error) {
      console.error('客户登录:', error)
      return Promise.resolve(null);
    }
  };

export const getAuthCode = async(params = null) => {
    try {
      const res = await $get($API.GETAUTHCODE);
      return res;
    } catch (error) {
      console.error('getAuthCode:', error)
      return Promise.resolve(null);
    }
};

//验证二维码
export const checkCode = async(params = null) => {
    try {
        const res = await $post($API.CHECKCODE, params);
        return res;
    } catch (error) {
        console.error('checkCode:', error)
        return Promise.resolve(null);
    }
};

  //支付宝认证
export const zfbAuth = async(params = null) => {
    console.log(params,'认证接口参数')
    try {
        const res = await $post($API.ZFBAUTH, params);
        return res;
    } catch (error) {
        console.error('zfbAuth:', error)
        return Promise.resolve(null);
    }
};

//获取支付宝认证结果
export const authResult = async(params = null) => {
    try {
        const res = await $get($API.AUTHRESULT + '?transferId=' + (params || ''));
        return res;
    } catch (error) {
        console.error('authResult:', error)
        return Promise.resolve(null);
    }
};

//更换手机号获取验证码
export const getUpdatePhoneCode = async(params = null) => {
    try {
        const res = await $post($API.GETUPDATEPHONE, params);
        return res;
    } catch (error) {
        console.error('getUpdatePhoneCode:', error)
        return Promise.resolve(null);
    }
};
//更换手机号
export const submitUpdatePhone= async(params = null) => {
    try {
        const res = await $post($API.UPDATEPHON, params);
        return res;
    } catch (error) {
        console.error('updatePhone:', error)
        return Promise.resolve(null);
    }
};

//修改密码
export const updatePassword= async(params = null) => {
    try {
        const res = await $post($API.UPDATEPWD, params);
        return res;
    } catch (error) {
        console.error('updatePassword:', error)
        return Promise.resolve(null);
    }
};

//退出登录
export const loginOutUser= async(params = null) => {
    try {
        const res = await $post($API.LOGINOUT);
        return res;
    } catch (error) {
        console.error('loginOutUser:', error)
        return Promise.resolve(null);
    }
};

// 获取用户信息
export const getAgentInfo = async(params = null) => {
    try {
        const res = await $get($API.AGENTINFO);
        return res;
    } catch (error) {
        console.error('authResult:', error)
        return Promise.resolve(null);
    }
};
