/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: page description
 * @Date: 2021-10-18 14:44:47
 * @LastEditTime: 2022-03-31 15:19:54
 */
import {createStore,applyMiddleware} from 'redux';
import thunk from 'redux-thunk'
import reducer from 'src/store/reducer';


let store=createStore(reducer,applyMiddleware(thunk))

export default store