/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 客户列表
 * @Date: 2022-03-21 15:37:26
 * @LastEditTime: 2022-04-11 15:51:12
 */

import "./index.less"
import { Layout, Form, Input, Select, Button, DatePicker, Table, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { tableConfig, testData } from "./table.config"
import {minPHONE, PWD, RESETPWD, USERNAME, CUSTROMERACCOUNT} from "src/config/regexp.config"
import { queryCustomer, addCustomer } from "model/customer"

//校验修改手机号传参
const checkAddParams = (obj) => {

  const res = {
    isPass: true,
    msg: false
  }

  if (!obj.userName) {
    res.isPass = false
    res.msg = '请输入用户名'
    return res
  }

  if (!CUSTROMERACCOUNT.test(obj.userName)) {
    res.isPass = false
    res.msg = '用户名由6~16位字母/数字/_@组成'
    return res
  }

  if (!obj.pwd) {
    res.isPass = false
    res.msg = '请输入密码'
    return res
  }

  if (!RESETPWD.test(obj.pwd)) {
    res.isPass = false
    res.msg = '密码由6~20位字母/数字/特殊字符组成'
    return res
  }

  if (!obj.phone) {
    res.isPass = false
    res.msg = '请输入手机号码'
    return res
  }

  if (!minPHONE.test(obj.phone)) {
    res.isPass = false
    res.msg = '当前手机号输入格式错误'
    return res
  }
  return res
}


export default function CustomerList() {
  //新增客户功能
  const updateCustomer = () => {

    const params = {
      userName: null,
      pwd: null,
      phone: null,
      desc: null
    }

    Modal.confirm({
      confirmLoading: false,
      maskClosable: false,
      keyboard: false,
      destroyOnClose: false,
      closeIcon: false,
      okText: '确定',
      title: '创建客户',
      content: (
        <Form
          name="addUser"
          autoComplete="off"
        >

          <Form.Item
            labelCol={{ span: 6 }}
            label="用户名"
            name="userName"
            rules={[{ required: true, message: '4~20位字母/数字/_@组成!' }]}
          >
            <Input placeholder="请输入用户名" onBlur={(ev) => params.userName = ev.target.value} />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            label="密码"
            name="pwd"
            rules={[{ required: true, message: '6~16位字母/数字/特殊字符组成!' }]}
          >
            <Input placeholder="请输入密码" onBlur={(ev) => params.pwd = ev.target.value} />
          </Form.Item>


          <Form.Item
            labelCol={{ span: 6 }}
            label="手机号"
            name="phone"
            rules={[{ required: true, message: '请输入手机号!' }]}

          >
            <div className="flex">
              <Input placeholder="输入手机号" type="number" onBlur={(ev) => params.phone = ev.target.value} />
            </div>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            label="备注信息"
            name="desc"
          >
            <div className="flex">
              <Input.TextArea allowClear showCount  placeholder="输入备注信息" type="text" onBlur={(ev) => params.desc = ev.target.value} />
            </div>
          </Form.Item>

        </Form>
      ),
      onOk: async (close) => {
        //校验数据合法性
        const checkRes = checkAddParams(params)
        if (!checkRes.isPass) {
          message.error(checkRes.msg)
          return Promise.reject(false)
        }
        const query = {
          username: params.userName,
          password: params.pwd,
          phone: params.phone,
          remark: params.desc
        }

        let res = await addCustomer(query)
        if (res && res.code === 200) {
          message.success('添加成功');
          close()
          // 更新列表
          getData()
        } else {
          message.success(res && res.message || '接口异常');
          return Promise.reject(res && res.message || '接口异常')
        }
      }
    });
  }
  const [params, setParams] = React.useState({
    username: null, //用户名
    phone: null,  //手机号
    cStatus: null,  //认证状态
    aStatus: null, //账户状态
    startTime: null, //创建的开始时间
    endTime: null, //创建的结束时间
  })
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState({
    page: 1,
    size: 20
  })

  const pagination = {
    simple: false,
    total: total,
    showQuickJumper: true,
    current: page.page,
    pageSize: page.size,
    pageSizeOptions: ['10', '20', '30', '40', '50'],
    showSizeChanger: true,
    showTotal: (count = total) => {
      return '共' + count + '条数据'
    },
    onChange: (page, size) => {
      setPage({
        page, size
      })
    },
  }

  const [tableData, setTableData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  // const [num, setNum] = React.useState(0)

  //监听传参
  const updateParams = (val, field) => {

    if (field != 'times') {
      setParams({
        ...params,
        [field]: val
      })
      return
    }

    //处理时间范围
    setParams({
      ...params,
      startTime: val && val[0]._d,
      endTime: val && val[1]._d
    })
  }

  const getData = async (val = null) => {
    setLoading(true)
    let tem = val ?? params;
    const query = {
      username: tem.username,
      phone: tem.phone,
      identityAuth: tem.cStatus,  // 认证状态
      state: tem.aStatus || null,   // 帐号状态
      createEndTime: tem.endTime ? Math.floor(((new Date(tem.endTime)).getTime()) / 1000) : null,
      createStartTime: tem.startTime ? Math.floor(((new Date(tem.startTime)).getTime()) / 1000) : null,
      ...page
    }

    let res = await queryCustomer(query)
    if (res && res.code === 200) {
      setLoading(false)
      const data = res.data && res.data.list && [...res.data.list] || [];
      const total = res.data && res.data.total || 0;
      setTotal(total)
      setTableData(data)
    } else {
      setLoading(false)
      return message.error(res && res.message || '系统异常')
    }
  }
  useEffect(async () => {
    await getData()
  }, [page]);


  //重置
  const formRef = React.useRef(null)

  const reset = async () => {
    await formRef.current.resetFields()
    const query = {
      username: null,
      phone: null,
      cStatus: null,
      aStatus: null,
      startTime: null,
      endTime: null,
    }
    setParams({
      ...query
    })
    setPage({
      page: 1,
      size: 20
    })
  }

  //查询
  const query = () => {
    getData()
  }



  //加新客户
  const addUser = () => {
    updateCustomer()
  }

  //查询的时间格式
  const monthFormat = 'YYYY/MM/DD HH:mm:ss';

  const navigate = useNavigate()

  //点击进入详情页
  const goDetails = (row) => {
    navigate("/customerDetail", {
      state: {
        brand: row.brand || null,
        userId: row.id
      }
    })
  }


  return (
    <>
      {/* 选项 */}
      <Layout.Content
        className="layout-content-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Form name="horizontal_login" layout="inline" ref={formRef} >
          {/* 用户名 */}
          <Form.Item name="username" label="用户名" className="mt-10 mb-10" colon={false}>
            <Input style={{ width: 150 }} value={params.username} placeholder="输入用户名" maxLength={60} allowClear className="pt-5 pb-5" onBlur={(e) => updateParams(e.target.value, 'username')} />
          </Form.Item>

          {/* 手机号 */}
          <Form.Item name="phone" label="手机号" className="mt-10 mb-10" colon={false}>
            <Input style={{ width: 150 }} value={params.phone} onBlur={(e) => updateParams(e.target.value, 'phone')} placeholder="输入客户手机号" maxLength={12} type="number" allowClear className="pt-5 pb-5" />
          </Form.Item>

          {/* 认证状态 */}
          <Form.Item name="cStatus" label="认证状态" className="mt-10 mb-10" colon={false}>
            <Select defaultValue="全部" style={{ width: 120 }} onChange={(e) => updateParams(e, 'cStatus')} >
              <Select.Option value={null}>全部</Select.Option>
              <Select.Option value={false}>未认证</Select.Option>
              <Select.Option value={true}>已认证</Select.Option>
            </Select>

            {/* 账号状态 */}
          </Form.Item>
          <Form.Item name="aStatus" label="账号状态" className="mt-10 mb-10" colon={false}>
            <Select defaultValue="全部" style={{ width: 120 }} onChange={(e) => updateParams(e, 'aStatus')} >
              <Select.Option value={null}>全部</Select.Option>
              <Select.Option value={1}>启用</Select.Option>
              <Select.Option value={2}>禁用</Select.Option>
            </Select>
          </Form.Item>
          {/* 创建时间 */}
          <Form.Item name="times" label="创建时间" className="mt-10 mb-10" colon={false}>
            <DatePicker.RangePicker format={monthFormat} showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
            }} onChange={(e) => updateParams(e, 'times')} />
          </Form.Item>

          {/* 查询/重置 */}
          <Form.Item className="action-obn mt-10 mb-10">
            <Button type="primary" onClick={reset} >
              重置
            </Button>
            <Button className="ml-10" style={{ backgroundColor: '#FD9924', borderColor: '#FD9924', color: 'white' }} onClick={query}>
              查询
            </Button>

            <Button className="ml-10" icon={<PlusOutlined />} onClick={addUser} style={{ borderColor: '#4E9AFB', color: '#4E9AFB' }}>
              添加客户
            </Button>
          </Form.Item>
        </Form>


      </Layout.Content>


      {/* table*/}
      <Layout.Content
        className="layout-content-background"
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
        }}
      >

        {/* 详情列表 */}
        <Table columns={tableConfig}
          scroll={{ x: 'calc(700px + 50%)' }}
          dataSource={tableData}
          pagination={pagination}
          rowKey={record => record.id}
          loading={loading}
          onRow={record => {
            return {
              onClick: () => goDetails(record), // 点击行
            };
          }}
        />
      </Layout.Content>
    </>
  )
}
