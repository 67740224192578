/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 无导航与顶部的路由注册
 * @Date: 2022-03-23 15:08:32
 * @LastEditTime: 2022-04-11 13:29:53
 */
import { useRoutes } from "react-router-dom";
 
import Login from "views/login/index"
import Auth from "views/auth/index"
import Agreement from "views/agreement/index"

export default function Routes() {

  let element = useRoutes([
    { path: "/login", element: <Login /> },
    { path: "/auth", element: <Auth /> },
    { path: "/agreement", element: <Agreement /> },
  ]);

  return element;
  
}