/*
 * @Author: 秦琛
 * @LastEditors: 秦琛
 * @description: page description
 * @Date: 2022-04-11 13:27:58
 * @LastEditTime: 2022-04-12 10:22:34
 */
import "./index.less"
import { connect } from 'react-redux'
import { setLogin } from "src/store/action/user"
import { UserOutlined, UnlockFilled } from "@ant-design/icons"
import { Form, Input, Button, message } from 'antd';
import { useEffect, useState } from "react"
import {useNavigate} from "react-router-dom"

function Agreement(props) {
    let userInfo = (props.userInfo && typeof props.userInfo === 'string') ? JSON.parse(props.userInfo) : props.userInfo || null;
    // const setLoginAction = props.setLoginAction;
    // let navigate = useNavigate();
    if(localStorage.getItem('_agentInfo')){
      let userData = localStorage.getItem('_agentInfo') || null;
      userInfo = JSON.parse(userData)
    //   setLoginAction && setLoginAction(userData)
    }

    console.log(userInfo,'userInfo用户协议==')
    // useEffect(() => {
    //   if (userInfo && userInfo.identityAuth) {
    //     return navigate("/");
    //   }
    // }, [])

  return (
    <div className="agreement">
      <h1>【HTTP代理商服务协议】</h1>
      {/* 1： 神龙  2：天启*/}
      {userInfo.brand === 1 && 
        <div>
                <p>一、总则<br></br>
                    1.1 神龙HTTP的所有权和运营权归南京小北信息科技有限公司。<br></br>
                    1.2 用户在注册/使用之前,应当仔细阅读本协议，再同意遵守本协议后方可成为神龙HTTP用户。
                        一旦注册成功/使用本产品，则用户与神龙HTTP之间自动形成协议关系,用户应当受本协议的约束。<br></br>
                    1.3 本协议可由神龙HTTP随时更新,用户可随时登录查阅最新协议，用户应不时关注并阅读最新版的协议及网站公告。
                        如用户不同意更新后的协议，可以且应立即停止接受网站依据本协议提供的服务；
                        如用户继续使用神龙HTTP提供的服务的，即视为同意更新后的协议。<br></br>
                    1.4 神龙HTTP的通知、公告、声明或其它类似内容是本协议的一部分。<br></br>
                </p>
                <p>二、用户帐号<br></br>
                    2.1 用户必须是具有完全民事行为能力的自然人。 <br></br>
                    2.2 用户有义务保证密码和帐号的安全,用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，神龙HTTP不承担任何责任。<br></br>
                    2.3 如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管,如有必要,请通知神龙HTTP。
                        因黑客行为或用户的保管疏忽导致帐号非法使用,神龙HTTP不承担任何责任。<br></br>
                    2.4 除非有法律规定或司法裁定，且征得神龙HTTP的同意，否则用户的神龙HTTP账号和密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
                </p>
                <p>三、使用规则<br></br>
                    3.1 遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、
                    《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号》、《全国人大常委会关于维护互联网安全的决定》 、
                    《互联网电子公告服务管理规定》 、《互联网新闻信息服务管理规定》 、《互联网著作权行政保护办法》 和
                    《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。<br></br>

                    3.2 用户禁止行为: <br></br>
                    (1) 不得传输或发表:煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论;<br></br>
                    (2) 从中国大陆向境外传输资料信息时必须符合中国有关法规;<br></br>
                    (3) 不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动;<br></br>
                    (4) 不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统;<br></br>
                    (5) 不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料;<br></br>
                    (6) 不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论;<br></br>
                    (7) 不得教唆他人从事本条所禁止的行为;<br></br>
                    (8) 不得利用在本站的账户进行牟利性经营活动;<br></br>
                    (9) 不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容;<br></br>
                    若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。<br></br>

                    3.3 根据国家相关要求，用户在使用神龙HTTP服务前需完成实名认证。
                    同时，神龙HTTP可能会就某些产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证，
                    您的账户只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。<br></br>
                </p>
                <p>四、隐私保护<br></br>
                    4.1 神龙HTTP不对外公开或向第三方提供单个用户的账号资料及用户在使用网络服务时存储在神龙HTTP的非公开内容,但下列情况除外:<br></br>
                    (1) 事先获得用户的明确授权;<br></br>
                    (2) 根据有关的法律法规要求;<br></br>
                    (3) 按照相关政府主管部门的要求;<br></br>
                    (4) 为维护社会公众的利益。<br></br>

                    4.2 神龙HTTP可能会与第三方合作向用户提供相关的网络服务,在此情况下，如该第三方同意承担与神龙HTTP同等的保护用户隐私的责任，则神龙HTTP有权将用户的账号资料等提供给该第三方。<br></br>
                    4.3 在不透露单个用户隐私资料的前提下，神龙HTTP有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。<br></br>
                </p>
                <p>五、责任声明<br></br>
                5.1 用户明确同意其使用神龙HTTP网络服务所存在的风险及一切后果将完全由用户本人承担，神龙HTTP对此不承担任何责任。<br></br>
                5.2 神龙HTTP无法保证网络服务一定能满足用户的要求， 也不保证网络服务的及时性、安全性、稳定性。<br></br>
                5.3 神龙HTTP不保证为方便用户而设置的外部链接的准确性和完整性；同时,对于该等外部链接指向的不由神龙HTTP实际控制的任何网页上的内容,神龙HTTP不承担任何责任。<br></br>
                5.4 对于因不可抗力或神龙HTTP不能控制的原因造成的网络服务中断或其它缺陷，神龙HTTP不承担任何责任,但将尽力减少因此而给用户造成的损失和影响。<br></br>
                5.5 对于向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失,神龙HTTP无需承担任何责任:<br></br>
                (1) 神龙HTTP向用户免费提供的各项网络服务；<br></br>
                (2) 神龙HTTP向用户赠送的任何产品或者服务。<br></br>
                5.6 神龙HTTP有权于任何时间暂时或永久修改或终止本服务(或其任何部分),无论其通知与否,神龙HTTP对用户和任何第三人均无需承担任何责任。<br></br>
                </p>
                <p>六、附则<br></br>
                6.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。<br></br>
                6.2 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。<br></br>
                6.3 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向江苏省南京市有管辖权的人民法院提起诉讼。<br></br>
                6.4 本协议解释权及修订权归南京小北信息科技有限公司所有。<br></br>
                </p>        
        </div>
      }
      {userInfo.brand === 2 && 
        <div>
            <p>一、总则<br></br>
            1.1 天启HTTP的所有权和运营权归南京光年之内网络科技有限公司。<br></br>
            1.2 用户在注册/使用之前,应当仔细阅读本协议，再同意遵守本协议后方可成为天启HTTP用户。一旦注册成功/使用本产品，则用户与天启HTTP之间自动形成协议关系,用户应当受本协议的约束。<br></br>
            1.3 本协议可由天启HTTP随时更新,用户可随时登录查阅最新协议，用户应不时关注并阅读最新版的协议及网站公告。
            如用户不同意更新后的协议，可以且应立即停止接受网站依据本协议提供的服务；如用户继续使用天启HTTP提供的服务的，即视为同意更新后的协议。<br></br>
            1.4 天启HTTP的通知、公告、声明或其它类似内容是本协议的一部分。<br></br>
            </p>
            <p>二、用户帐号<br></br>
            2.1 用户必须是具有完全民事行为能力的自然人。<br></br>
            2.2 用户有义务保证密码和帐号的安全,用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，天启HTTP不承担任何责任。<br></br>
            2.3 如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管,如有必要,请通知天启HTTP。
            因黑客行为或用户的保管疏忽导致帐号非法使用,天启HTTP不承担任何责任。<br></br>
            2.4 除非有法律规定或司法裁定，且征得天启HTTP的同意，否则用户的天启HTTP账号和密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。<br></br>
            </p>
            <p>三、使用规则<br></br>
            3.1 遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号》、《全国人大常委会关于维护互联网安全的决定》 、《互联网电子公告服务管理规定》 、《互联网新闻信息服务管理规定》 、《互联网著作权行政保护办法》 和《信息网络传播权保护条例》等有关计算机互联网规定和知识产
            权的法律和法规、实施办法。<br></br>
            3.2 用户禁止行为: <br></br>
            (1) 不得传输或发表:煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论;<br></br>
            (2) 从中国大陆向境外传输资料信息时必须符合中国有关法规;<br></br>
            (3) 不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动;<br></br>
            (4) 不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统;<br></br>
            (5) 不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料;<br></br>
            (6) 不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论;<br></br>
            (7) 不得教唆他人从事本条所禁止的行为;<br></br>
            (8) 不得利用在本站的账户进行牟利性经营活动;<br></br>
            (9) 不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容;<br></br>
            若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。<br></br>
            3.3 根据国家相关要求，用户在使用天启HTTP服务前需完成实名认证。同时，天启HTTP可能会就某些产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证，您的账户只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。<br></br>
            </p>
            <p>四、隐私保护<br></br>
            4.1 天启HTTP不对外公开或向第三方提供单个用户的账号资料及用户在使用网络服务时存储在天启HTTP的非公开内容,但下列情况除外:<br></br>
            (1) 事先获得用户的明确授权;<br></br>
            (2) 根据有关的法律法规要求;<br></br>
            (3) 按照相关政府主管部门的要求;<br></br>
            (4) 为维护社会公众的利益。<br></br>
            4.2 天启HTTP可能会与第三方合作向用户提供相关的网络服务,在此情况下，如该第三方同意承担与天启HTTP同等的保护用户隐私的责任，则天启HTTP有权将用户的账号资料等提供给该第三方。<br></br>
            4.3 在不透露单个用户隐私资料的前提下，天启HTTP有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。<br></br>

            </p>
            <p>五、责任声明<br></br>
            5.1 用户明确同意其使用天启HTTP网络服务所存在的风险及一切后果将完全由用户本人承担，天启HTTP对此不承担任何责任。<br></br>
            5.2 天启HTTP无法保证网络服务一定能满足用户的要求， 也不保证网络服务的及时性、安全性、稳定性。<br></br>
            5.3 天启HTTP不保证为方便用户而设置的外部链接的准确性和完整性,同时,对于该等外部链接指向的不由天启HTTP实际控制的任何网页上的内容,天启HTTP不承担任何责任。<br></br>
            5.4 对于因不可抗力或天启HTTP不能控制的原因造成的网络服务中断或其它缺陷，天启HTTP不承担任何责任,但将尽力减少因此而给用户造成的损失和影响。<br></br>
            5.5 对于向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失,天启HTTP无需承担任何责任:<br></br>
            (1) 天启HTTP向用户免费提供的各项网络服务；<br></br>
            (2) 天启HTTP向用户赠送的任何产品或者服务。<br></br>
            5.6 天启HTTP有权于任何时间暂时或永久修改或终止本服务(或其任何部分),无论其通知与否,天启HTTP对用户和任何第三人均无需承担任何责任。<br></br>
            </p>
            <p>六、附则<br></br>
            6.1本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。<br></br>
            6.2如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。<br></br>
            6.3如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向江苏省南京市有管辖权的人民法院提起诉讼。<br></br>
            6.4本协议解释权及修订权归南京光年之内网络科技有限公司所有。<br></br>
            </p>
        </div>
      }
      
    </div>
  )
}

const mapState = (state) => ({
  isAuthenticated: state.isAuthenticated
})

const mapDispatch = (dispatch) => {
  return {
    setLoginAction: (val) => {
        dispatch(setLogin(val))
    }
  }
}
export default connect(
    mapState,
    mapDispatch
  )(Agreement)