/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 认证结果
 * @Date: 2022-03-24 15:18:39
 * @LastEditTime: 2022-04-09 12:24:38
 */
import { connect } from 'react-redux'
import {setAuth, setLogin} from "src/store/action/user"
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import store from "src/store";

function AuthRes(props) {
  const { setAuthAction } = props
  const [time, setTime] = React.useState(3)

  const navigate = useNavigate();
  let userInfo = (props.userInfo && typeof props.userInfo === 'string') ? JSON.parse(props.userInfo) : props.userInfo || null;
  const setLoginAction = props.setLoginAction;

  if(localStorage.getItem('_agentInfo')){
    let userData = localStorage.getItem('_agentInfo') || null;
    userInfo = JSON.parse(userData)
    setLoginAction && setLoginAction(userData)
  }
  console.log(userInfo,'用户信息');

  

  let timer = null
  React.useEffect(() => {
    if(userInfo.identityAuth){
      if (time > 0) {
        clearTimeout(timer)
        timer = setTimeout(() => setTime(time - 1), 1000)
      } else {
        //跳转首页
        if (time === 0) {
          let cacheData = localStorage.getItem('_agentInfo');
          let userData = (cacheData && typeof cacheData === 'string') ? JSON.parse(cacheData) : cacheData || null;
          store.dispatch(setLogin(JSON.stringify(userData)))
          setAuthAction(true)
          // navigate('/')
        }
      }
    }
    
  }, [time])


  const goHome = ()=>{
    setAuthAction(true)
    navigate('/')
  }

  return (
    <Result
      status="success"
      title="恭喜您，实名认证成功!"
      extra={[
        <Button key="console" className='mt-20' onClick={goHome}>
          跳转至首页 <span className='pl-10 font-main'>({time}S)</span>
        </Button>,
      ]}
    />
  )
}


const mapDispatch = (dispatch) => {
  return {
    setAuthAction: (val) => {
      dispatch(setAuth(val))
    }
  }
}


export default connect(
  null,
  mapDispatch
)(AuthRes)
