/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 支付宝实名认证
 * @Date: 2022-03-24 14:14:42
 * @LastEditTime: 2022-04-11 11:28:19
 */

import { connect } from 'react-redux'
import { Form, Input, Button, message } from 'antd';
import { NAME, IDCARD } from "src/config/regexp.config"
import qcord from "assets/images/qcord.png"
import {QRCodeCanvas} from 'qrcode.react';
import { zfbAuth, authResult } from "model/user"
import store from "src/store"
import {setLogin} from "src/store/action/user";
function AuthRealName(props) {
  let userInfo = (props.userInfo && typeof props.userInfo === 'string') ? JSON.parse(props.userInfo) : props.userInfo || null;
  const setLoginAction = props.setLoginAction;
  //验证信息
  const [pay, setPay] = React.useState({
    name: '',  //身份证姓名 -
    code: '',//身份证码
  })

  //生成二维码链接
  const [hasPay, setHasPay] = React.useState(null);

  const [certifyId, setCertifyId] = React.useState(null);
  let resultId = null;

  //是否认证成功
  const [hasSuccess, setHasSuccess] = React.useState(true);

  //是否要查询结果
  const [hasRes, setHasRes] = React.useState(false);
  

  //修改值
  const updatePay = (val, type = 'name') => {
    const obj = Object.assign(pay, {
      [type]: val
    })
    setPay(obj)
  }

  //校验传参
  const checkParams = (obj) => {
    const res = {
      isPass: true,
      msg: ''
    }
    if (!obj.name) {
      res.isPass = false
      res.msg = '请输入身份证姓名'
      return res
    }

    if (!NAME.test(obj.name)) {
      res.isPass = false
      res.msg = '请输入正确格式的身份证姓名'
      return res
    }

    if (!obj.code) {
      res.isPass = false
      res.msg = '请输入身份证号码'
      return res
    }

    if (!IDCARD.test(obj.code)) {
      res.isPass = false
      res.msg = '请输入正确格式的身份证号码'
      return res
    }

    return res
  }


  //点击支付宝认证，获取认证二维码
  const onFinish = async () => {

    //校验数据合法性
    const checkRes = checkParams(pay)
    if (!checkRes.isPass) {
      return message.error(checkRes.msg)
    }

    const params = {
      name: pay.name,
      idCard: pay.code
    }
    let res = await zfbAuth(params);

    if(res && res.code === 200){
      resultId = res.data.certifyId || null;
      setCertifyId(res.data && res.data.certifyId || null)
      //api获取 二维码链接
      setHasPay(res.data && res.data.url)
    } else {
      return message.error(res.message || '系统异常')
    }
  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  //重新认证
  const resetPay = () => {
    setHasPay(null)
    setCertifyId(null)
    resultId = null;
    setPay({
      name: '',
      code: '',
    })
    setHasSuccess(true)
    // setHasRes(false)
  }

  //获取认证结果
  const getAuthRes = async () => {

    //查询接口获取结果
    let res = await authResult(certifyId || resultId)
    if(res && res.code === 200){
      if(res.data === null){
        return message.error('暂未获取到认证结果请稍后再试')
      } else if(res.data){
        // setHasRes(true)
        let cacheData = localStorage.getItem('_agentInfo');
        let userData = (cacheData && typeof cacheData === 'string') ? JSON.parse(cacheData) : cacheData || null;
        userData.identityAuth = res.data;
        localStorage.setItem('_agentInfo', JSON.stringify(userData))
        //2. 查询到认证结果
        setHasSuccess(true)
        props.next()
      } else {
        setHasSuccess(false)
        return message.error('认证失败')
      }
    } else {
      return message.error(res.data.message || '系统异常')
    }
  }

  //填写认证信息
  const realAuth = () => {
    return (<div className='auth-phone pb-40'>
      <p>为保障您的账户安全，请进行信息认证</p>
      <p>平台授权支付宝安全认证，平台不会泄露您的认证信息</p>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        name="checkPhone"
        autoComplete="off"
      >
        <Input placeholder='输入身份证姓名' maxLength="30" className='mt-20' onBlur={(ev) => updatePay(ev.target.value)} />

        <div className='flex mt-20'>
          <Input placeholder='输入身份证号码' maxLength="36" onBlur={(ev) => updatePay(ev.target.value, 'code')} />
        </div>
        <Form.Item className='mt-40'>
          <Button type="primary" htmlType="submit">
            支付宝认证
          </Button>
        </Form.Item>
      </Form>
    </div>)

  }


  // 获取创建的二维码页面
  const creatQImg = (props) => {

    return (
      <div className='auth-phone pb-40'>
        <p>打开支付宝扫描下方二维码</p>
        <p>您的信息由支付宝审核，本平台无法保留您的识别信息</p>

        <div className="img-box">
          <QRCodeCanvas value={hasPay} level='M' size={200}/>
        </div>

        <div className='pay-action mt-40 flex justify-between'>
          <Button onClick={resetPay}>
            重新录入身份
          </Button>
          <Button type="primary" onClick={getAuthRes}>
            查询结果
          </Button>
        </div>

      </div>
    )

  }

  //认证失败
  const failPage = () => {
    return (
      <div className='auth-phone pb-40'>
        <h3>认证失败，请重新认证</h3>
        {/* <p className='mt-30 fail-msg'>3次认证失败后，当天无法再实名认证，请仔细核对身份信息</p> */}
        <div className='pay-action mt-40 flex justify-center'>
          <Button onClick={resetPay}>
            重新认证
          </Button>
        </div>

      </div>
    )
  }

//hasSuccess && creatQImg() || failPage()
// hasRes: 是否查询结果   hasSuccess: 是否认证成功   hasPay: 是否生成二维码链接
  return (
    <>
      {
        // !hasRes ? (!hasPay ? realAuth() : creatQImg() ) :(
        //   !hasSuccess &&  failPage()
        // )
        !hasPay ? realAuth() : (
          hasSuccess && creatQImg() || failPage()
        )
      }
    </>
  )
}

const mapState = (state) => ({
  userInfo: state.userInfo,
  isAuthenticated: state.isAuthenticated
})
const mapDispatch = (dispatch) => {
  return {
    setLoginAction: (val) => {
      dispatch(setLogin(val))
    }
  }
}
export default connect(
    mapState,
    mapDispatch
)(AuthRealName)
