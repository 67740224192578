/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 下拉选项框
 * @Date: 2022-03-21 14:45:04
 * @LastEditTime: 2022-04-08 16:14:21
 */

import "./index.less"
import { Menu, Dropdown, Modal, Form, Input, Button, message } from "antd"
import { useEffect, useState } from "react"
import { DownOutlined,UpOutlined,FormOutlined, EditOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { minPHONE, CODE, RESETPWD } from "src/config/regexp.config"
import store from "src/store"
import {setLogin} from "src/store/action/user";
// 接口
import { updatePassword, getUpdatePhoneCode, submitUpdatePhone, getAgentInfo } from "model/user"



export default function SelectAction() {
  //校验修改手机号传参
const checkUpdatePhoneParams = (obj, allCheck = true) => {

  const res = {
    isPass: true,
    msg: false
  }

  if (!obj.oldPhone) {
    res.isPass = false
    res.msg = '请输入当前手机号'
    return res
  }
  if (!minPHONE.test(obj.oldPhone)) {
    res.isPass = false
    res.msg = '当前手机号输入格式错误'
    return res
  }

  if (!obj.newPhone) {
    res.isPass = false
    res.msg = '请输入新手机号'
    return res
  }

  if (!minPHONE.test(obj.newPhone)) {
    res.isPass = false
    res.msg = '新手机号输入格式错误'
    return res
  }
  if (obj.newPhone === obj.oldPhone) {
    res.isPass = false
    res.msg = '新设置的手机号不能是当前手机号'
    return res
  }

  // 是否都校验
  if (allCheck) {
    if (!obj.confirmCode) {
      res.isPass = false
      res.msg = '请输入验证码'
      return res
    }

    if (!CODE.test(obj.confirmCode)) {
      res.isPass = false
      res.msg = '验证码只能是六位数字'
      return res
    }
  }

  return res
}

//校验修改手机号传参
const checkUpdatPwdParams = (obj) => {

  const res = {
    isPass: true,
    msg: false
  }

  if (!obj.oldPwd) {
    res.isPass = false
    res.msg = '请输入原密码'
    return res
  }

  if (!obj.newOld) {
    res.isPass = false
    res.msg = '请输入新密码'
    return res
  }

  if (!RESETPWD.test(obj.newOld)) {
    res.isPass = false
    res.msg = '新密码输入格式错误'
    return res
  }

  if (obj.newOld === obj.oldPwd) {
    res.isPass = false
    res.msg = '新密码不能与原密码相同'
    return res
  }
  if (!obj.comfirmPwd) {
    res.isPass = false
    res.msg = '请重复新密码'
    return res
  }

  if (obj.newOld !== obj.comfirmPwd) {
    res.isPass = false
    res.msg = '新密码与重复密码不一致'
    return res
  }
  return res
}

const onFinish = (values) => {
  console.log('Success:', values);
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

//修改密码
const updatePwd = () => {
  const params = {
    oldPwd: null,
    newOld: null,
    comfirmPwd: null,
  }
  Modal.confirm({
    confirmLoading: false,
    maskClosable: false,
    keyboard: false,
    destroyOnClose: false,
    closeIcon: false,
    okText: '保存',
    title: '修改密码',
    content: (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          label="原密码"
          name="oldPwd"
          rules={[{ required: true, message: '输入原密码!' }]}
        >
          <Input.Password placeholder="输入原密码" onBlur={(ev) => params.oldPwd = ev.target.value} />
        </Form.Item>

        <Form.Item
          label="新密码"
          name="newOld"
          rules={[{ required: true, message: '设置新密码!' }]}
        >
          <Input.Password placeholder="设置新密码" onBlur={(ev) => params.newOld = ev.target.value} />
        </Form.Item>


        <Form.Item
          label="确认密码"
          name="comfirmPwd"
          rules={[{ required: true, message: '重复新密码!' }]}
        >
          <Input.Password placeholder="重复新密码" onBlur={(ev) => params.comfirmPwd = ev.target.value} />
        </Form.Item>

      </Form>
    ),
    onCancel: () => {
      downSelect()
    }, 
    onOk: async (close) => {
      downSelect()
      //校验数据合法性
      const checkRes = checkUpdatPwdParams(params)
      if (!checkRes.isPass) {
        message.error(checkRes.msg)
        return Promise.reject(false)
      }
      const query = {
        originalPassword: params.oldPwd,
        newPassword: params.newOld,
        checkPassword: params.comfirmPwd
      }

      //api 通信
      let res = await updatePassword(query);
      if (res && res.code === 200) {
        message.success('修改成功');
        close()
      } else {
        message.error(res && res.message || '修改失败');
        return Promise.reject(res && res.message || '修改失败')
      }
    }
  });

}



//修改手机号码  打开模态框
const updatePhone = async (props) => {
  // 获取用户当前手机号，更新缓存
  let resp = await getAgentInfo();
  if(resp && resp.code === 200){
    let cacheData = localStorage.getItem('_agentInfo');
    let userData = (cacheData && typeof cacheData === 'string') ? JSON.parse(cacheData) : cacheData || null;
    userData.phone = resp.data.phone || userData.phone;
    localStorage.setItem('_agentInfo', JSON.stringify(userData))
    store.dispatch(setLogin(JSON.stringify(userData)))
  }


  let timer = null; //定时器
  let countDown = 0; //当前倒计时数据
  const state = store.getState();
  let userInfo = state && JSON.parse(state.userInfo)

  // 判断是否存取缓存时间
  // const cacheTime = sessionStorage.getItem('_phoneTimer') || false;
  // const seconds = cacheTime ? (Math.floor((new Date()).getTime() / 1000) - Math.floor(Number(cacheTime) / 1000)) : 0;

  //操作倒计时函数
  const changeCountDown = (count = 60) => {
    if (countDown && countDown > 0) return;
    countDown = count;
    const domButton = document.querySelector("#button span")

    clearInterval(timer)
    domButton.parentNode.disabled = true

    const changVal = () => {
      if (countDown < 1) {
        clearInterval(timer);
        countDown = 0
        domButton.innerHTML = '获取验证码'
        domButton.parentNode.disabled = false
        // sessionStorage.removeItem('_phoneTimer')
        return
      } else {
        domButton.innerHTML = countDown
      }
    }

    changVal()
    timer = setInterval(() => {
      countDown--;
      changVal()
    }, 1000)
  }


  const params = {
    oldPhone: userInfo.phone,
    newPhone: null,
    confirmCode: null
  }

  //初始化执行
  // setTimeout(() => {
  //   if (seconds < 60 && seconds > 0) {
  //     changeCountDown(60 - seconds)
  //   }
  // }, 10)

  // // 点击获取验证码按钮
  const getPhoneCode = async () => {
    //校验新旧手机号数据合法性
    const checkRes = checkUpdatePhoneParams(params, false)
    if(!checkRes.isPass){
      return message.error(checkRes.msg)
    }
    // sessionStorage.setItem('_phoneTimer', (new Date()).getTime())
    changeCountDown(60)
    const query = {
      phone: params.newPhone,
      originalPhone: params.oldPhone
    }
    let res = await getUpdatePhoneCode(query)
    if(res && res.code === 200){
      message.success('验证码已发送')
    } else {
      countDown = 0;
      // sessionStorage.removeItem('_phoneTimer');
      changeCountDown(0)
      message.error(res.data && res.data.message || '发送失败')
      return Promise.reject(res.data && res.data.message || '发送失败')
    }

  }


  Modal.confirm({
    confirmLoading: false,
    maskClosable: false,
    keyboard: false,
    destroyOnClose: false,
    closeIcon: false,
    okText: '保存',
    title: '修改手机号',
    content: (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          label="当前手机号"
          name="oldPhone"
          rules={[{ required: true, message: '输入当前手机号!' }]}
        >
          <Input placeholder="请输入当前手机号" defaultValue={params.oldPhone} disabled type="number" onBlur={(ev) => params.oldPhone = ev.target.value} />
        </Form.Item>

        <Form.Item
          label="新手机号"
          name="newPhone"
          rules={[{ required: true, message: '请输入新手机号!' }]}
        >
          <Input placeholder="请输入新手机号" type="number" onBlur={(ev) => params.newPhone = ev.target.value} />
        </Form.Item>


        <Form.Item
          label="验证码"
          name="idenfy"
          rules={[{ required: true, message: '验证码!' }]}
        >
          <div className="flex">
            <Input placeholder="输入验证码" style={{ width: '110px' }} type="number" onBlur={(ev) => params.confirmCode = ev.target.value} />
            <Button style={{ width: '80px' }} className="check-code" onClick={getPhoneCode} disabled={countDown > 0} id="button">
              {countDown > 0 ? countDown : '获取验证码'}
            </Button>
          </div>
        </Form.Item>

      </Form>
    ),
    onCancel: () => {
      downSelect()
    }, 
    onOk: async (close) => {
      downSelect()
      //校验数据合法性
      const checkRes = checkUpdatePhoneParams(params)
      if (!checkRes.isPass) {
        message.error(checkRes.msg)
        return Promise.reject(false)
      }
      const query = {
        code: params.confirmCode,
        newPhone: params.newPhone,
        originalPhone: params.oldPhone
      }
      //api 通信
      let res = await submitUpdatePhone(query);
      if(res && res.code === 200){
        message.success('修改成功');
        let cacheData = localStorage.getItem('_agentInfo');
        let userData = (cacheData && typeof cacheData === 'string') ? JSON.parse(cacheData) : cacheData || null;
        userData.phone = params.newPhone;
        localStorage.setItem('_agentInfo', JSON.stringify(userData));
        // setLogin(JSON.stringify(userData));
        store.dispatch(setLogin(JSON.stringify(userData)))
        close()
      } else {
        message.error(res.message || '修改失败');
        return Promise.reject(res.message || '修改失败')
      }
      countDown = 0;
      // sessionStorage.removeItem('_phoneTimer');
      changeCountDown(0)
    }
  });

}


// 下拉功能配置
const funcs = [
  {
    name: '修改密码',
    key: 'pwd',
    icon: <FormOutlined />
  },
  {
    name: '修改手机号',
    key: 'phone',
    icon: <EditOutlined />
  }
]

// 下拉选项
const menu = (funcs) => {

  //选中操作
  const changeEvent = ({ key }) => {
    if (key === 'pwd') {
      updatePwd()
    } else {
      updatePhone()
    }

  }



  return (<Menu onClick={changeEvent}>
    {
      funcs && funcs.map((item) => {
        return (
          <Menu.Item icon={item.icon} key={item.key}>
            {item.name}
          </Menu.Item>
        )
      })
    }
  </Menu>)
}
  const [selected, setSelected] = useState(false)



  //触发下拉
  const downSelect = (e) => {
    setSelected(!selected)
  }

  return (
    <Dropdown overlay={menu(funcs)} trigger="click" arrow={true} onVisibleChange={downSelect}>
      <a className="ant-dropdown-link" >
        {
          !selected && <DownOutlined style={{ color: 'white', fontSize: '16px' }} className="ml-10 v-middle" /> ||
          <UpOutlined style={{ color: 'white' }} className="ml-10 v-middle" />
        }
      </a>
    </Dropdown>
  )
}
