/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: 面包屑导航
 * @Date: 2022-03-23 18:07:10
 * @LastEditTime: 2022-03-23 18:37:24
 */

import { Breadcrumb } from "antd";
import { useNavigate, useLocation,Link } from "react-router-dom"
import { BreadcrumbMap } from "src/routes"

export default function BreadNav() {

  const loc = useLocation();
  const nav = BreadcrumbMap[loc.pathname]

  // console.log("nav", nav)
  //
  // console.log("loc", loc.pathname)


  return <>

    <Breadcrumb className="bread-nav ml-10">


      {
        nav && nav.length && nav.map((item, index) => {
          return <Breadcrumb.Item key={index}>
            <Link to={item.path}>{item.label}</Link>
          </Breadcrumb.Item>
        })
      }



    </Breadcrumb>
  </>

}
