/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: 项目入口文件
 * @Date: 2021-09-09 10:40:15
 * @LastEditTime: 2022-03-23 17:50:30
 */

import { Provider } from 'react-redux';
import { render } from 'react-dom';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

import 'assets/css/public.less';
import "assets/css/ant.reset.less"

import store from "src/store"
import LayoutPage from "src/layoutPage"
import { post, get } from 'utils/request';

//配置文件
import api from 'config/api.config'
import regexp from 'config/regexp.config'

//挂载全局方法
React.$post = post

React.$get = get
//api配置文件
React.$api = api

//正则配置文件
React.$regexp = regexp

render(

    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <ReactRouter.BrowserRouter>
          <LayoutPage />
        </ReactRouter.BrowserRouter>
      </ConfigProvider>
    </Provider>
,
  document.getElementById('root')
);