/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: 路由实体部分
 * @Date: 2021-10-18 16:08:48
 * @LastEditTime: 2022-03-23 18:06:14
 */
import { useRoutes, Navigate } from "react-router-dom";

import CustomerList from "views/customers/list/index"
import CustomerDetail from "views/customers/details/index"
import Package from "views/customers/package/index"
import Orders from "views/orders/history/index"


//面包屑路由映射
export const BreadcrumbMap = {
  "/": [{
    label: '客户',
    path: "/"
  }],
  "/customerDetail": [{
    label: '客户',
    path: "/"
  },
  {
    label: '客户详情',
    path: "/customerDetail"
  }],

  "/package": [{
    label: '客户',
    path: "/"
  },
  {
    label: '客户套餐',
    path: "/package"
  }],
  "/orders": [{
    label: '历史订单',
    path: "/package"
  }]
}


export default function Routes() {

  let element = useRoutes([
    {
      path: "/",
      element: <CustomerList />,
    },
    { path: "/customerDetail", element: <CustomerDetail /> },
    { path: "/package", element: <Package /> },
    { path: "/orders", element: <Orders /> },
    { path: "*", element: <Navigate to="/" /> },
  ]);

  return element;

}