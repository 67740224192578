/*
 * @Author: 薛莹
 * @LastEditors: 秦琛
 * @description: page description
 * @Date: 2021-09-09 13:34:15
 * @LastEditTime: 2022-04-09 10:10:33
 */
import axios from "axios";
import { AESAUTH, encrypt, decrypt} from "./aes";
import store from "src/store"
import { message } from 'antd';
import {setLogin} from "../store/action/user";
axios.defaults.withCredentials = true;
// 基础地址
let baseURL = '/api';

if (process.env.NODE_ENV === 'production') {
  baseURL = process.env.common['API'] + baseURL;
}

const service=axios.create({
  baseURL,
  withCredentials:true,
  timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(config=>{
  const state = store.getState();
  let userInfo = (state && state.userInfo && typeof state.userInfo === 'string') ? JSON.parse(state.userInfo) : state.userInfo || null;
  config.headers['X-TOKEN'] = (userInfo && userInfo.token) || null;
 // && process.env.NODE_ENV !== 'development'
 if(AESAUTH[config.url]){
    config.headers['Content-Type']='text/plain';
    config.data = encrypt(config.data)
 } else {
    config.headers['Content-Type']='application/json';
 }
  return config;
},error=>{
  console.log(error);
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(response=>{
    // console.log(response,'response')
    //响应正确
    const code = response.data && response.data.code;
    if (code === 401 || code === 10003 || code === 20002 || code === 20003) {
        console.log(response,'response=====清楚缓存')
        localStorage.removeItem('_agentInfo')
        window.location = '/login'
        message.error(response.data && response.data.message || '系统异常')
        return Promise.resolve(false);
    }

    if(response.status >= 200 && response.status <= 210){
        const data=response.data
        return data
    }
    return response.data;
},error=>{
    console.log('接口断开了==========');
    const status = error.response && error.response.status || 'error';
    if (status === 401) {
        message.error(error)
        localStorage.removeItem('_agentInfo')
        window.location = '/login'
        return Promise.resolve(error.response.data || '系统异常');
    } else {
        console.log(error,'error')
        return Promise.resolve(error.response && error.response.data || (error || '系统异常'));
    }
})


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url,params={}){
    try{
     return service({
       url,
       params,
       method:'GET',
     })
    }catch(error){
      console.error('get:', error);
    }
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
 export function post(url, data={}){
  try {
    return service({
      url,
      data,
      method: 'POST',
    });
  } catch (error) {
    console.error('post:', error);
  }
}
