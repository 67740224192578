/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: 左侧导航
 * @Date: 2021-10-18 16:00:42
 * @LastEditTime: 2022-04-06 11:30:49
 */

import "./index.less"
import { useLocation } from 'react-router'
import { Menu } from "antd";
import {
  UnorderedListOutlined ,
  UserOutlined ,
  MailOutlined,
} from "@ant-design/icons";

export default function MenuNav() {

  const pathName = (useLocation()).pathname.slice(1)

   

  console.log("xxxxxxxx",(useLocation()).pathname.slice(1))




  return (
    <Menu
      defaultSelectedKeys={pathName}
      defaultOpenKeys={["customer"]}
      mode="inline"
      theme="dark"
      className="mt-40"
      style={{
        backgroundColor:'#304156',
        color:'white'
      }}
    >

      <Menu.SubMenu
        key="customer"
        icon={<UserOutlined />}
        title="客户"
      >
        <Menu.Item key="customer-list"> 
        <ReactRouter.Link to="/">客户列表</ReactRouter.Link>
        </Menu.Item>
        <Menu.Item key="customer-package">
        <ReactRouter.Link to="/package">客户套餐</ReactRouter.Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="orders" icon={<UnorderedListOutlined />}>
      <ReactRouter.Link to="/orders">历史订单</ReactRouter.Link>
      </Menu.Item>
    </Menu>
  );
}
