/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 历史订单table 
 * @Date: 2022-03-22 17:14:26
 * @LastEditTime: 2022-04-08 15:35:37
 */

import moment from "moment";
import { PAY_TYPE, ORDER_STATUS } from "src/config/constant"
import {_payTypeOption, _mealTypeOption, _mealStateOption, _ipTypeOption, _orderType} from 'src/utils/enumerateData'

//订单状态
const orderStatus = Object.values(ORDER_STATUS).reduce((pre, context) => ({
  ...pre,
  [context.value]: context.label
}), {})


//支付方式
const payType = Object.values(PAY_TYPE).reduce((pre, context) => ({
  ...pre,
  [context.value]: context.label
}), {})



export const tableConfig = [
  {
    title: '订单编号',
    align: 'center',
    dataIndex: 'orderNo',
    onCell: () => {
      return {
        style: {
          'minWidth': '160px',
        },
      };
    },
  },
  {
    title: '订单类型',
    align: 'center',
    dataIndex: 'orderType',
    width: 180,
    render: function (el, context) {
      return el && _orderType[el] || '--'
    }
  },
  {
    title: '支付金额（￥）',
    align: 'center',
    dataIndex: 'price',
    onCell: () => {
      return {
        style: {
          'minWidth': '160px',
        },
      };
    },
  },

  {
    title: '支付方式',
    align: 'center',
    dataIndex: 'payType',
    onCell: () => {
      return {
        style: {
          'minWidth': '120px',
        },
      };
    },
    render: function (el, context) {
      return payType[el + ''] || '--'
    }
  },

  {
    title: '支付时间',
    align: 'center',
    dataIndex: 'payTime',
    onCell: () => {
      return {
        style: {
          'minWidth': '160px',
        },
      };
    },
    sorter: {
      compare: (a, b) => a.payTime - b.payTime,
      multiple: 1,
    },
    sorter:true,
    render: function (el, context) {
      return `${context.payTime && moment(context.payTime * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}`
    }
  },
  {
    title: '所属客户',
    align: 'center',
    dataIndex: 'username',
    onCell: () => {
      return {
        style: {
          'minWidth': '160px',
        },
      };
    },
    render: function (el, context) {
      return el || '--'
    }
  },
];

