import {$API, $post, $get} from "utils/ajax"
// 查询二级客户
export const queryCustomer = async(params = null) => {
    try {
        const res = await $post($API.QUERYCUSTOMER, params);
        return res;
    } catch (error) {
        console.error('二级客户查询:', error)
        return Promise.resolve(null);
    }
};

// 添加客户
export const addCustomer = async(params = null) => {
    try {
        const res = await $post($API.ADDCUSTOMER, params);
        return res;
    } catch (error) {
        console.error('新增二级客户:', error)
        return Promise.resolve(null);
    }
};
// 查询客户详情
export const queryCustomerDetail = async(params = null) => {
    try {
        const res = await $post($API.QUERYCUSTOMERDETAIL, params);
        return res;
    } catch (error) {
        console.error('二级客户详情:', error)
        return Promise.resolve(null);
    }
};
// 客户套餐/历史套餐
export const queryCustomerMeal = async(params = null) => {
    try {
        const res = await $post($API.CUSTOMERMEAL, params);
        return res;
    } catch (error) {
        console.error('客户套餐:', error)
        return Promise.resolve(null);
    }
};


// 客户白名单查询
export const queryWhiteList = async(params = null) => {
    try {
        const res = await $post($API.GETWHITELIST, params);
        return res;
    } catch (error) {
        console.error('客户白名单:', error)
        return Promise.resolve(null);
    }
};
