/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 容器/ 登录业务
 * @Date: 2022-03-23 13:39:11
 * @LastEditTime: 2022-04-09 13:01:48
 */

import App from 'views/app'
import { useNavigate } from "react-router-dom"
import { useEffect } from 'react'
import Gpage from "src/routes/global"

import { connect } from 'react-redux'
import store from "src/store"
import {  setLogin } from "src/store/action/user"

function LayoutContainer(props) {
  // let { userInfo, setLoginAction } = props;
  let userInfo = (props.userInfo && typeof props.userInfo === 'string') ? JSON.parse(props.userInfo) : props.userInfo || null;
  const setLoginAction = props.setLoginAction;
  let navigate = useNavigate();
  if(localStorage.getItem('_agentInfo')){
    let userData = localStorage.getItem('_agentInfo') || null;
    userInfo = JSON.parse(userData)
    setLoginAction && setLoginAction(userData)
  }
  // console.log(userInfo,'userInfo')
  useEffect(() => {
    if (userInfo) {
      if (!userInfo.identityAuth) {
        console.log('未认证成功');
        return navigate("/auth");
      }
      return navigate("/");
    } else {
      let userData = localStorage.getItem('_agentInfo') || null;
      if(!userData){
        return navigate("/login");
      } else {
        userInfo = userData;
        setLogin(userData)
      }
    }
  //  navigate("/login");
  }, [])

  return (
    <>
      {
        userInfo && userInfo.identityAuth && <App /> || <Gpage />
      }
    </>
  )
}


const mapState = (state) => ({
  userInfo: state.userInfo,
  isAuthenticated: state.isAuthenticated
})

const mapDispatch = (dispatch) => {
  return {
    setLoginAction: (val) => {
      dispatch(setLogin(val))
    }
  }
}

export default connect(
  mapState,
  mapDispatch
)(LayoutContainer)
