/*
 * @Author: 秦琛
 * @LastEditors: 秦琛
 * @description: 枚举数据
 * @Date: 2021-09-09 13:34:15
 * @LastEditTime: 2022-04-09 15:32:10
 */
// 套餐付费类型
export const _payTypeOption = {
    1: '付费',
    3: '测试'
}
//套餐类型
export const _mealTypeOption = {
    0: '包量',
    1: '包时',
    // 10: '计次',
    // 60: '福利',
    // 70: '不限量'
}
// 用户套餐状态
export const _mealStateOption = {
    1: '启用',
    2: '禁用',
    3: '用完',
    4: '到期'
}
// IP类型
export const _ipTypeOption = {
    10: '普通IP',
    20: '优质IP'
}
//  订单类型
export const _orderType = {
    1: '购买',
    2: '续费',
    3: '退款',
    // 4: '充值',
    5: '补偿',
    // 6: '赠送'
}
