/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: api地址配置文件
 * @Date: 2021-10-18 17:50:41
 * @LastEditTime: 2022-04-02 14:22:55
 */


export default {
  test:'/test',
  AUTHLOGIN: '/auth/login',  // 客户登录接口
  GETAUTHCODE : '/auth/getAuthCode', //获取客户认证短信验证码
  CHECKCODE : '/auth/checkAuthCode', //验证短信验证码
  ZFBAUTH : '/auth/zfbAuth', //支付宝认证
  AUTHRESULT: '/auth/getAuthResult', // 获取实名认证结果
  GETUPDATEPHONE: '/auth/getAuthCode4UpdatePhone',   // 客户更换手机号获取短信验证码
  UPDATEPHON: '/user/update/phone',  // 更换手机号
  UPDATEPWD: '/user/update/password',   // 修改密码
  LOGINOUT: '/auth/loginOut',    // 退出登录
  AGENTINFO: '/user/getCurr',   // 获取登录信息
  // 客户列表接口
  QUERYCUSTOMER: '/user/secondaryCustomer/page',   // 分页查询二级客户
  ADDCUSTOMER: '/user/secondaryCustomer/add',  // 新增二级客户
  QUERYCUSTOMERDETAIL: '/user/customer/detail',  // 二级客户详情
  CUSTOMERMEAL: '/meal/proxy/list',   // 客户套餐/历史套餐
  GETWHITELIST: '/whiteList/getWhiteLists', // 获取白名单
  HISTORYORDER:"/order/queryList" //获取历史订单列表
}
