/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 用户详情
 * @Date: 2022-03-23 09:40:19
 * @LastEditTime: 2022-04-11 14:48:15
 */

import moment from "moment";
import {_payTypeOption, _mealTypeOption, _mealStateOption, _ipTypeOption, _orderType} from 'src/utils/enumerateData'


const tem = [
  {
    title: '用户名 | 手机号',
    dataIndex: 'name',
    width: 180,
    render: function (el, context) {
      return `${context.name}  ${context.phone}`
    }
  },
  {
    title: '认证状态',
    dataIndex: 'cstatus',
    width: 180,
    render: function (el, context) {
      if (el == 1) {
        return '已认证'
      } else {
        return '未来认证'
      }

    }
  },
  {
    title: '可用套餐数量/总数',
    width: 180,
    render: function (el, context) {
      return `${context.pNum}/ ${context.pTotsl}`
    }
  },
  {
    title: '创建时间',
    width: 120,
    dataIndex: 'createTime',
    sorter: {
      compare: (a, b) => a.english - b.english,
      multiple: 1,
    },
  },
  {
    title: '最近登录地点时间',
    dataIndex: 'logintime',
    render: function (el, context) {
      return `${context.address}/ ${context.loginTime}`
    }
  },
  {
    title: '账号状态',
    width: 180,
    render: function (el, context) {
      if (el == 1) {
        return '已认证'
      } else {
        return '未认证'
      }

    }
  },
];


// 历史套餐
export const historyPackage = [
  {
    title: '套餐ID',
    align: 'center',
    dataIndex: 'sequence',
    onCell: () => {
      return {
        style: {
          'minWidth': '120px',
        },
      };
    },
  },
  {
    title: '所属客户',
    align: 'center',
    dataIndex: 'username',
    onCell: () => {
        return {
            style: {
                'minWidth': '120px',
            },
        };
    },
  },
  {
    title: '套餐名称',
    align: 'center',
    dataIndex: 'name',
    onCell: () => {
      return {
        style: {
          'minWidth': '160px',
        },
      };
    },
  },
  {
    title: '套餐类型',
    align: 'center',
    dataIndex: 'proxyType',
    onCell: () => {
      return {
        style: {
          'minWidth': '100px',
        },
      };
    },
    render: function (el, context) {
      return `${(context.proxyType || context.proxyType === 0) && _mealTypeOption[context.proxyType] || '--'}`
    }
  },
  {
    title: '付费类型',
    align: 'center',
    dataIndex: 'mealPayType',
    onCell: () => {
      return {
        style: {
          'minWidth': '100px',
        },
      };
    },
    render: function (el, context) {
      return `${context.mealPayType && _payTypeOption[context.mealPayType] || '--'}`
    }
  },
  {
    title: '提取秘钥',
    align: 'center',
    dataIndex: 'key',
    onCell: () => {
      return {
        style: {
          'minWidth': '150px',
        },
      };
    },
  },
  {
    title: '账号|密码',
    align: 'center',
    dataIndex: 'authKey',
    onCell: () => {
      return {
        style: {
          'minWidth': '170px',
        },
      };
    },
    render: function (el, context) {
      return `${context.authKey} | ${context.authSecret}`
    }
  },
  {
    title: '提取上限',
    align: 'center',
    dataIndex: 'total',
    onCell: () => {
      return {
        style: {
          'minWidth': '120px',
        },
      };
    },
  },
  {
    title: '已提取',
    align: 'center',
    dataIndex: 'used',
    onCell: () => {
      return {
        style: {
          'minWidth': '120px',
        },
      };
    },
  },
  {
    title: '起止时间',
    align: 'center',
    onCell: () => {
      return {
        style: {
          'minWidth': '180px',
        },
      };
    },
    dataIndex: 'createTime',
    sorter: {
      compare: (a, b) => a.createTime - b.createTime,
      multiple: 1,
    },
    //只有包时套餐 按照此规则 3天<到期时间<30天，绿色；到期时间<3天，红色；到期时间>30天，不显示到期时间
    render: function (el, context) {
      const start = context.createTime && context.createTime || null;
      const end = context.endTime && context.endTime || null;
      const now = new Date().getTime()/1000;
      // 包时
      if(context.proxyType === 1){
        // 0~1小时
        if(((end - now) / 60 / 60) < 1 && ((end - now) / 60 / 60) > 0){
          return <div className='wrap'>
            <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='limit' style={{color: '#E6A23C'}}>即将到期</span>
          </div>
        } else if((end - now) / 60 / 60 < 24 && (end - now) / 60 / 60 >=1){  // 1~24小时
          return <div className='wrap'>
            <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='limit' style={{color: '#E6A23C'}}>剩余{Math.floor((end - now) / 60 /60)}小时</span>
          </div>
        } else if(((end - now) / 60 / 60 / 24) < 4 && ((end - now) / 60 / 60 / 24) > 1){  // 1~3天
          return <div className='wrap'>
            <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='limit' style={{color: '#E6A23C'}}>剩余{Math.floor((end - now) / 60 /60 / 24)}天</span>
          </div>
        } else if(((end - now) / 60 / 60 / 24) <= 30 && ((end - now) / 60 / 60 / 24) >= 4){  // 4~30天
          return <div className='wrap'>
            <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='limit' style={{color: '#E6A23C'}}>剩余{Math.floor((end - now) / 60 /60 / 24)}天</span>
          </div>
        } else {
          return <div className='wrap'>
            <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
          </div>
        }
      } else {
        return <div className='wrap'>
          <span className='time'>{start && moment(start * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
          <span className='time'>{end && moment(end * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
        </div>
      }
    }
  },

  {
    title: '状态',
    align: 'center',
    onCell: () => {
      return {
        style: {
          'minWidth': '120px',
        },
      };
    },
    dataIndex: 'state',
    render: function (el, context) {
      if(el){
        if(el === 2 || el === 3 || el === 4){
          return <span style={{color: '#f10215'}}>{context.state && _mealStateOption[context.state] || '--'}</span>
        } else {
          return <span style={{color: '#4E9AFB'}}>{context.state && _mealStateOption[context.state] || '--'}</span>
        }
      } else {
        return '--'
      }

    }
  },
  {
    title: '关联订单',
    align: 'center',
    width: 160,
    dataIndex: 'orderNo'
  },
];
// 历史订单
export const historyOrders = [
  {
    title: '订单编号',
    dataIndex: 'orderId',
    width: 180,
  },
  {
    title: '订单类型',
    dataIndex: 'orderType',
    width: 180
  },
  {
    title: '订单状态',
    dataIndex: 'ptype',
    width: 180
  },
  {
    title: '支付金额（￥）',
    dataIndex: 'pcost',
    width: 180
  },
  {
    title: '支付方式',
    dataIndex: 'paytype',
    width: 180
  },
  {
    title: '支付时间',
    width: 120,
    dataIndex: 'payTime',
    sorter: {
      compare: (a, b) => a.english - b.english,
      multiple: 1,
    },
  },



  {
    title: '订单描述',
    dataIndex: 'orderDesc',
    width: 300
  },
];
// IP白名单
export const ipWhite = [
  {
    title: 'IP',
    align: 'center',
    dataIndex: 'clientIP',
    onCell: () => {
      return {
        style: {
          'minWidth': '180px',
        },
      };
    },
    render: function (el, context) {
      return `${context.clientIP || '--'}`
    }
  },
  {
    title: '备注',
    align: 'center',
    dataIndex: 'desc',
    onCell: () => {
      return {
        style: {
          'minWidth': '180px',
        },
      };
    },
  },
  {
    title: '添加人',
    align: 'center',
    dataIndex: 'creator',
    onCell: () => {
      return {
        style: {
          'minWidth': '120px',
        },
      };
    },
  },

  {
    title: '添加时间',
    align: "center",
    dataIndex: 'createTime',
    sorter: {
      compare: (a, b) => a.createTime - b.createTime,
      multiple: 1,
    },
    onCell: () => {
      return {
        style: {
          'minWidth': '180px',
        },
      };
    },
    render: function (el, context) {
      return `${context.createTime && moment(context.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}`
    }
  },
  {
    title: '删除人',
    align: 'center',
    dataIndex: 'deleter',
    onCell: () => {
      return {
        style: {
          'minWidth': '120px',
        },
      };
    },
  },
  {
    title: '删除时间',
    align: "center",
    dataIndex: 'deleteTime',
    sorter: {
      compare: (a, b) => a.deleteTime - b.deleteTime,
      multiple: 1,
    },
    onCell: () => {
      return {
        style: {
          'minWidth': '180px',
        },
      };
    },
    render: function (el, context) {
      return `${context.deleteTime && moment(context.deleteTime * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}`
    }
  },
];





// 历史套餐数据
export const histortOrder = [
  {
    key: '1',
    orderId: '1585055dfdss',
    ptype: 1,
    pcost: 99,
    paytype: 1,
    payTime: '2020/02/20',
    status: 1,
    porder: '4353454353453',
    pid: 425474,
    orderDesc: '我只是一个订单'
  },
  {
    key: '2',
    orderId: 'ddgeeee33333',
    ptype: 1,
    pcost: 99,
    paytype: 1,
    payTime: '2020/02/20',
    status: 1,
    porder: '4353454353453',
    pid: 425474,
    orderDesc: '我是第二订单'
  },
  {
    key: '3',
    orderId: 'fdvvzxcwe323434',
    ptype: 1,
    pcost: 99,
    paytype: 1,
    payTime: '2020/02/20',
    status: 1,
    porder: '4353454353453',
    pid: 425474,
    orderDesc: '我是第三订单'
  },
  {
    key: '4',
    orderId: 'hgdfgdgdrrrr',
    ptype: 1,
    pcost: 99,
    paytype: 1,
    payTime: '2020/02/20',
    status: 1,
    porder: '4353454353453',
    pid: 425474,
    orderDesc: '我是第四订单'
  },
];



