/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 应用容器
 * @Date: 2021-09-09 13:15:41
 * @LastEditTime: 2022-04-08 15:50:27
 */

import "views/index";
import { test } from "model/test";
import Router from "src/routes/";
import { connect } from 'react-redux'
import { setLogin } from "src/store/action/user"
import { Layout, Tooltip, Modal, Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined } from "@ant-design/icons";
import SelectAction from "components/SelectAction";
import MenuNav from "components/MenuNav";
import BreadNav from "components/BreadNav";

import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

//头像图
import tx from "assets/images/tx.png";
//接口
import { loginOutUser } from "model/user"
function App(props) {
  //是否折叠左侧导航
  const [collapsed, setCollapsed] = useState(false)

  let userInfo = (props.userInfo && typeof props.userInfo === 'string') ? JSON.parse(props.userInfo) : props.userInfo || null;
  //左侧导航切换
  const toggle = () => {
    setCollapsed(!collapsed)
  };


  const navigate = useNavigate()


  //跳转路由
  const goRoute = () => {
    navigate("/")
  }

  //左侧导航切换类
  const showUser = classNames({
    hides: collapsed,
    shows: !collapsed
  })

  // 登出系统
  const layout = () => {
    Modal.confirm({
      content: (
        <div>
          确定退出登录?
        </div>
      ),
      onOk: async (close) => {
        // console.log(props,"确认退出登录")
        // 1. 调用退出接口
        let res = await loginOutUser()
        if(res && res.code === 200){
          //2. 清除redux 中登录信息
          localStorage.removeItem('_agentInfo')
          props.setLoginAction(null)
          //3. 调转到登录页
          navigate("/login")
          //4. 隐藏弹出窗
          close()
        } else {
          message.error(res && res.message || '系统异常')
          return Promise.reject(res && res.message || '系统异常')
        }
      }
    })
  }


  return (
    <Layout className="container">
      <Layout.Sider
        className="left-nav"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        {/* logo */}
        <section className={"hover logo text-center mt-20 mb-20 " + showUser} onClick={goRoute}>
          <h1>代理商后台</h1>
        </section>

        {/* 登录人 */}
        <section className={"hover user " + showUser} onClick={goRoute}>
          <div className="user-icon text-center">
            <img src={tx} alt="头像" className="user-img" />
          </div>

          <div className="user-name mt-40 text-center">
            <span>{userInfo && userInfo.username}</span>
            <SelectAction />
          </div>
        </section>

        {/* 导航 */}
        <MenuNav />

        {/* 退出登录功能 */}
        <Tooltip placement="topLeft" title="退出系统" className="layout-logOut">
          <LogoutOutlined className="layout-obn hover" onClick={layout} />
        </Tooltip>
      </Layout.Sider>

      <Layout className="site-layout">
        {/* 公共顶部 */}
        <Layout.Header
          className="site-layout-background"
          style={{ padding: 0 }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}

          {/* 面包屑导航 */}
          <BreadNav />

        </Layout.Header>

        {/* 内容主体部分 */}
        <Layout.Content
          className="site-layout-background">
          <Router />
        </Layout.Content>
      </Layout>
    </Layout>

  );

}


const mapState = (state) => ({
  userInfo: state.userInfo,
  isAuthenticated: state.isAuthenticated
})

const mapDispatch = (dispatch) => {
  return {
    setLoginAction: (val) => {
      dispatch(setLogin(val))
    }
  }
}


export default connect(
  mapState,
  mapDispatch
)(App)
