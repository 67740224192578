/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 操作state
 * @Date: 2021-10-18 14:44:58
 * @LastEditTime: 2022-03-31 15:23:16
 */


const initalState = {
  isAuthenticated: false, //当前登录用户是否已完成认证
  userInfo: null //当前用户登录信息
}

const reducer = (state = initalState, action) => {
  switch (action.type) {
    //设置用户是否已认证
    case 'AUTH':
      console.log("reducer 设置是否认证", action)
      return {
        ...state,
        isAuthenticated: action.value || false
      }

    //用户登录信息
    case 'LOGIN':
      // console.log("reducer 设置是否登录", state)
      return  Object.assign({}, state, {
        userInfo: action.value || null
      })


    
    default:
      return initalState
  }
}
export default reducer