/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: 历史订单相关接口
 * @Date: 2022-04-02 14:23:37
 * @LastEditTime: 2022-04-02 15:31:22
 */


import {$API,$post} from "utils/ajax"

//测试接口
export const postOrders = async(params = null) => {
  try {
    const res = await $post($API.HISTORYORDER,params);
    if(res.code === 200){
      return res.data
    }
    return [];
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve([]);
  }
};
