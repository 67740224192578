/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: page description
 * @Date: 2022-03-22 16:20:03
 * @LastEditTime: 2022-04-08 13:37:58
 */
import moment from "moment";
export const tableConfig = [
    {
        title: '用户名 | 手机号',
        align: 'center',
        dataIndex: 'username',
        onCell: () => {
            return {
                style: {
                    'minWidth': '180px',
                },
            };
        },
        render: function (el, context) {
            if(context.state === 2){
                return <span style={{color: '#d9d9d9'}}>{context.username} | {context.phone}</span>
            } else {
                return <span>{context.username} | {context.phone}</span>
            }

        }
    },
    {
        title: '认证状态',
        align: 'center',
        dataIndex: 'isIdentityAuth',
        onCell: () => {
            return {
                style: {
                    'minWidth': '120px',
                },
            };
        },
        render: function (el, context) {
            if (el) {
                return <span style={{color: '#1890ff'}}>已认证</span>
            } else {
                return <span style={{color: '#f10215'}}>未认证</span>
            }

        }
    },
    {
        title: '可用套餐数量/总数',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    'minWidth': '180px',
                },
            };
        },
        render: function (el, context) {
            return `${context.usefulMealNum || 0} / ${context.totalMealNum || 0}`
        }
    },
    {
        title: '创建时间',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    'minWidth': '150px',
                },
            };
        },
        dataIndex: 'createTime',
        sorter: {
            compare: (a, b) => a.createTime - b.createTime,
            multiple: 1,
        },
        render: function (el, context) {
            return `${context.createTime && moment(context.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}`
        }
    },
    {
        title: '最近登录地点|时间',
        align: 'center',
        dataIndex: 'lastLoginRegion',
        onCell: () => {
            return {
                style: {
                    'minWidth': '180px',
                },
            };
        },
        render: function (el, context) {
            return <div className="wrap">
                <span className="item">{context.lastLoginRegion || '--'}</span>
                <span className="item"> {context.lastIP || '--'} </span>
                <span className="item">{context.loginTime && moment(context.loginTime * 1000).format('YYYY-MM-DD HH:mm:ss') || '--'}</span>
            </div>
        }
    },
    {
        title: '账号状态',
        align: 'center',
        width: 180,
        dataIndex: 'state',
        render: function (el, context) {
            if (el === 1) {
                return <span style={{color: '#1890ff'}}>启用</span>
            } else {
                return <span style={{color: 'red'}}>禁用</span>
            }

        }
    },
];


export const testData = [
    {
        key: '1',
        name: 'John Brown',
        phone: 15850514487,
        cstatus: 0,
        pNum: 60,
        pTotsl: 100,
        createTime: '2020/02/20',
        address: '浦口区江山路',
        loginTime: '2020/09/09 12:33:44',
        aStatus: 1
    },
    {
        key: '2',
        name: 'John Brown',
        phone: 15850514487,
        cstatus: 1,
        pNum: 60,
        pTotsl: 100,
        createTime: '2020/02/20',
        address: '浦口区江山路',
        loginTime: '2020/09/09 12:33:44',
        aStatus: 0
    },
    {
        key: '3',
        name: 'John Brown',
        phone: 15850514487,
        cstatus: 0,
        pNum: 60,
        pTotsl: 100,
        createTime: '2020/02/20',
        address: '浦口区江山路',
        loginTime: '2020/09/09 12:33:44',
        aStatus: 1
    },
    {
        key: '4',
        name: 'John Brown',
        phone: 15850514487,
        cstatus: 1,
        pNum: 60,
        pTotsl: 100,
        createTime: '2020/02/20',
        address: '浦口区江山路',
        loginTime: '2020/09/09 12:33:44',
        aStatus: 0
    },
];

