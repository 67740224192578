/*
 * @Author: 朱占伟
 * @LastEditors: 秦琛
 * @description: 历史订单
 * @Date: 2022-03-21 15:37:26
 * @LastEditTime: 2022-04-09 16:18:53
 */

import "./index.less"
import { Layout, Form, Input, Select, Button, DatePicker, Table, message } from "antd";
import moment from "moment"

import { postOrders } from "model/order"
import { tableConfig } from "./table.config"
import OrderType from "components/OrderType";


export default function CustomerList(customerId = null) {

  //查询列表参数
  const [params, setParams] = React.useState({
    orderNo: null, //订单编号
    orderType: null,  //订单类型
    payStart: null, //创建的开始时间
    payEnd: null, //创建的结束时间
  })
  const [loading, setLoading] = React.useState(false);
  //监听传参
  const updateParams = (val, field) => {

    // console.log("payStart", val)
    if (field != 'times') {
      setParams({
        ...params,
        [field]: val
      })
      return
    }

    //处理时间范围
    setParams({
      ...params,
      payStart: val && val[0] && val[0]._d,
      payEnd: val && val[1] && val[1]._d
    })
  }

  //重置功能
  const formRef = React.useRef(null)
  const reset = async () => {
    formRef.current.resetFields()
    setParams({
      orderNo: null, //订单编号
      orderType: null,  //订单类型
      payStart: null, //创建的开始时间
      payEnd: null, //创建的结束时间
    })
    setPage({
      page: 1,
      size: 20
    })
    // console.log(params,'params===')
  }

  //查询功能
  const query = async () => {
    await getOrderList()
  }

  //api: 获取历史订单列表数据
  const [tableData, setTableData] = React.useState([])
  const getOrderList = async (val = null) => {
    setLoading(true)
    let tem = val ?? params
    const param = {
      customerId: customerId && JSON.stringify(customerId) !== '{}' && customerId || null,
      paySort:  tem.paySort ?? 1,
      ...page
    }
    if (tem.orderNo) {
      param.orderNo = tem.orderNo
    }
    if (tem.orderType) {
      param.orderType = tem.orderType
    }
    if (tem.payStart) {
      param.payStart = Math.floor(((new Date(tem.payStart)).getTime()) / 1000)

    }
    if (tem.payEnd) {
      param.payEnd = Math.floor(((new Date(tem.payEnd)).getTime()) / 1000)
    }
    // console.log("接口传参==", param)

    const res = await postOrders(param)
    console.log(res,'res=======');
    if(res){
      setLoading(false)
      //设置table 列表值
      setTableData(res.data)
      //获取总数两
      setTotal(res.totalSize || 0)
    } else {
      setLoading(false)
      return message.error('系统异常')
    }
    
  }

  //分页功能相关
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState({
    page: 1,
    size: 20
  })

  React.useEffect(async () => {
    await getOrderList()
  }, [page])

  const pagination = {
    simple: false,
    total: total,
    showQuickJumper: true,
    current: page.page,
    pageSize: page.size,
    pageSizeOptions: ['10', '20', '30', '40', '50'],
    showSizeChanger: true,
    showTotal: (count = total) => {
      return '共' + count + '条数据'
    },
    onChange: (page, size) => {
      setPage({
        page, size
      })

      console.log("相关功能", page, size)
      // setParams(async (pre) => {
      //   await getOrderList(pre)
      //   console.log("相关功能2", pre)
      //   return pre
      // })

    },
  }


  //排序功能
  const handleTableChange = async (pagination, filters, sorter) => {
    // console.log("xxxxxxxxxhandleTableChangexxxxxxxxx", pagination, filters, sorter)
    let paySort = 1
    if (sorter.field === 'payTime' && sorter.order === 'ascend') {
      paySort = 0
    }

    await getOrderList({
      ...params,
      paySort
    })
  }






  const monthFormat = 'YYYY/MM/DD HH:mm:ss';

  return (
    <>
      {/* 选项 */}
      <Layout.Content
        className="layout-content-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >


        <Form name="horizontal_login" layout="inline" ref={formRef} >
          {/* 订单编号 */}
          <Form.Item name="orderNo" label="订单编号" className="mt-10 mb-10" colon={false}>
            <Input onBlur={(e) => updateParams(e.target.value, 'orderNo')} style={{ width: 150 }} placeholder="输入订单编号" maxLength={60} allowClear className="pt-5 pb-5" />
          </Form.Item>

          {/* 订单类型 */}
          <Form.Item name="orderType" label="订单类型" className="mt-10 mb-10" colon={false}>
            <Select defaultValue="全部" style={{ width: 120 }} onChange={(e) => updateParams(e, 'orderType')}>
              {OrderType()}
            </Select>
          </Form.Item>


          {/* 支付时间 */}
          <Form.Item name="times" label="支付时间" className="mt-10 mb-10" colon={false}>
            <DatePicker.RangePicker onChange={(e) => updateParams(e, 'times')} showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
            }} format={monthFormat} />
          </Form.Item>



          {/* 查询/重置 */}
          <Form.Item className="action-obn mt-10 mb-10">
            <Button type="primary" onClick={reset}>
              重置
            </Button>
            <Button onClick={query} className="ml-10" style={{ backgroundColor: '#FD9924', borderBlockColor: '#FD9924', color: 'white' }}>
              查询
            </Button>
          </Form.Item>
        </Form>


      </Layout.Content>


      {/* table*/}
      <Layout.Content
        className="layout-content-background"
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
        }}
      >

        {/* 详情列表 */}
        <Table scroll={{ x: 'calc(700px + 50%)' }} loading={loading} columns={tableConfig} dataSource={tableData} pagination={pagination} rowKey={record => record.orderNo} onChange={handleTableChange} />
      </Layout.Content>
    </>
  )
}
