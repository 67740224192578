/*
 * @Author: 朱占伟
 * @LastEditors: 朱占伟
 * @description: 用户相关action
 * @Date: 2021-10-18 14:53:54
 * @LastEditTime: 2022-03-24 16:11:02
 */


//设置是否认证
export const setAuth = (value = true) => ({
  type: 'AUTH',
  value
})


//设置登录信息
export const setLogin = (value = null) => ({
  type: 'LOGIN',
  value
})